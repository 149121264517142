import Counter from 'components/Counter';
import { useCreatingMode } from 'contexts/CreatingModeProvider';
import { useOrderCart } from 'hooks/useOrderCart';
import { useOrderViewer } from 'hooks/useOrderViewer';
import React from 'react';

import styles from './styles.module.css';
import type { ServiceCounterProps } from './types';

const ServiceCounter: React.FC<ServiceCounterProps> = ({ id, value }) => {
	const { draftOrder, activeSuborder, utils } = useOrderViewer();
	const { creatingMode } = useCreatingMode();
	const cart = useOrderCart();

	const source = utils.isOnSuborderSplittingPage() ? activeSuborder : draftOrder;
	const handler = creatingMode ? cart.updateServiceQuantityInNewOrder : source.onQuantityChangeInService;
	return <Counter value={value} onQuantityChange={(quantity) => handler(id, quantity)} className={styles.servicesCounter} />;
};

export default ServiceCounter;
