import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import axios from 'axios';
import type { ProductFilterNode } from 'components/OrderCreatePageComponents/ProductFilter/types';
import type { SelectOption } from 'components/Select/types';
import localForage from 'localforage';
import { PRICE_CHANGE_OPTIONS } from 'mock/priceChange';
import { ISingleOrder } from 'models/IOrder';
import { IOrdersFilters } from 'models/IOrdersFilters';
import type { PriceChangeDTO, PriceChangeOption } from 'models/IPriceChange';
import { IBrand, ICategory, IProducts } from 'models/IProducts';
import { sortTreeAlphabetically } from 'pages/CreateOrder/mockDataServer';
// import { colorantData } from 'pages/OrderServices/mockData';
import { loadPaintBaseToneOptions, loadPaintCollectionOptions } from 'pages/OrderServices/optionLoaders';
import { API_URL } from 'services/api';
import { clientsQueryKeys, orderQueryKeys } from 'services/queryKeys';
import { uuid } from 'utils/shared';

import apiClient from '../auth/apiClient';
import { clientsSliceApi } from '../clients/clientsSliceApi';

const getAuthToken = async () => {
	try {
		const token = await localForage.getItem('token');
		return token;
	} catch (error) {
		// eslint-disable-next-line no-console
		console.error('Error getting auth token:', error);
		return null;
	}
};

const axiosInstance = axios.create({
	baseURL: 'https://back.unitb.ua/api/v1',
});

export const ordersSliceApi = createApi({
	reducerPath: 'orders',
	baseQuery: fetchBaseQuery({ baseUrl: API_URL }),
	tagTypes: [orderQueryKeys.orders()],
	endpoints: (builder) => ({
		getOrders: builder.query({
			queryFn: async (queryParams) => {
				const authToken = await getAuthToken();
				try {
					const response = await axiosInstance.get('/orders', {
						params: new URLSearchParams(queryParams),
						headers: {
							Authorization: `Bearer ${authToken}`,
							'Content-Type': 'application/json',
						},
					});

					if (response.status !== 200) {
						throw new Error(response.statusText);
					}

					return response;
				} catch (error) {
					throw error;
				}
			},
			providesTags: (result = []) => [orderQueryKeys.ordersOnly(), ...result.data.map(({ id }) => orderQueryKeys.order(id))],
		}),
		getClientsOrders: builder.query({
			queryFn: async ({ id, queryParams }) => {
				const authToken = await getAuthToken();
				try {
					const response = await axiosInstance.get(`/clients/${id}/orders`, {
						params: new URLSearchParams(queryParams),
						headers: {
							Authorization: `Bearer ${authToken}`,
							'Content-Type': 'application/json',
						},
					});

					if (response.status !== 200) {
						throw new Error(response.statusText);
					}

					return response;
				} catch (error) {
					throw error;
				}
			},
			providesTags: (result = []) => [orderQueryKeys.ordersOnly(), ...result.data.map(({ id }) => orderQueryKeys.order(id))],
		}),
		getPriceChangeOptions: builder.query<PriceChangeOption[], void>({
			queryFn: () => {
				return new Promise((res) => {
					setTimeout(() => {
						res({ data: PRICE_CHANGE_OPTIONS });
					}, 500);
				});
			},
		}),
		changeProductsPrice: builder.mutation<void, PriceChangeDTO>({
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			queryFn: (dto) =>
				new Promise((res) => {
					setTimeout(() => {
						res({ data: null });
					}, 2500);
				}),
			invalidatesTags: (_, __, order) => [orderQueryKeys.ordersOnly(), orderQueryKeys.order(order.orderId)],
		}),
		createOrder: builder.mutation({
			queryFn: async (body) => {
				const authToken = await getAuthToken();
				try {
					const response = await axiosInstance.post('/orders', body, {
						headers: {
							Authorization: `Bearer ${authToken}`,
							'Content-Type': 'application/json',
							Accept: 'application/json',
						},
					});

					if (response.status !== 201) {
						throw new Error(response.statusText);
					}

					return response.data;
				} catch (error) {
					throw error;
				}
			},
			invalidatesTags: () => [orderQueryKeys.ordersOnly()],
		}),
		getOrderById: builder.query<ISingleOrder, string>({
			queryFn: async (id) => {
				const authToken = await getAuthToken();
				try {
					const response = await axiosInstance.get(`/orders/${id}`, {
						headers: {
							Authorization: `Bearer ${authToken}`,
							'Content-Type': 'application/json',
						},
					});

					if (response.status !== 200) {
						throw new Error(response.statusText);
					}

					return response.data;
				} catch (error) {
					throw error;
				}
			},
			providesTags: (result, __, id) => (result ? [orderQueryKeys.order(id)] : []),
		}),
		deleteOrder: builder.mutation({
			// @ts-ignore
			queryFn: async (id: string) => {
				const authToken = await getAuthToken();
				try {
					const response = await axiosInstance.delete(`/orders/${id}`, {
						headers: {
							Authorization: `Bearer ${authToken}`,
							'Content-Type': 'application/json',
						},
					});

					if (response.status !== 204) {
						throw new Error(response.statusText);
					}

					return { data: response.data };
				} catch (error) {
					return { error };
				}
			},
			invalidatesTags: () => [orderQueryKeys.ordersOnly()],
			async onQueryStarted(id, { dispatch, queryFulfilled }) {
				const optimisticOrderDelete = dispatch(
					ordersSliceApi.util.updateQueryData('getOrderById', String(id), (draft) => {
						Object.assign(draft ?? {}, {});
					}),
				);
				try {
					await queryFulfilled;
				} catch {
					optimisticOrderDelete.undo();
				}
			},
		}),
		updateOrder: builder.mutation({
			queryFn: async (order) => {
				const authToken = await getAuthToken();
				const orderData = { ...order };
				delete orderData.id;

				try {
					const response = await axiosInstance.patch(`/orders/${order.id}`, orderData, {
						headers: {
							Authorization: `Bearer ${authToken}`,
							'Content-Type': 'application/json',
						},
					});

					if (response.status !== 200) {
						throw new Error(response.statusText);
					}

					return response.data;
				} catch (error) {
					throw error;
				}
			},
			invalidatesTags: (order) => [orderQueryKeys.ordersOnly(), orderQueryKeys.order(order?.id)],
			async onQueryStarted(order, { dispatch, queryFulfilled }) {
				const optimisticOrderUpdate = dispatch(
					ordersSliceApi.util.updateQueryData('getOrderById', String(order.id), (draft) => {
						Object.assign(draft ?? {}, order);
					}),
				);
				try {
					await queryFulfilled;
					dispatch(clientsSliceApi.util.invalidateTags([clientsQueryKeys.client(order?.client?.id)]));
				} catch {
					optimisticOrderUpdate.undo();
				}
			},
		}),
		updateSubOrder: builder.mutation({
			queryFn: async (order) => {
				const authToken = await getAuthToken();
				const orderData = { ...order };
				delete orderData.id;
				try {
					const response = await axiosInstance.patch(`/orders/${order.id}`, orderData, {
						headers: {
							Authorization: `Bearer ${authToken}`,
							'Content-Type': 'application/json',
						},
					});

					if (response.status !== 200) {
						throw new Error(response.statusText);
					}
					return response.data;
				} catch (error) {
					throw error;
				}
			},
		}),
		getProductFilters: builder.query({
			query: () => '/product-filters',
			transformResponse: (filters) => {
				const brandFiltersCopy = structuredClone({
					...filters[0],
					children: filters[0].children.map((item) => ({ name: item, id: uuid(), value: item })),
				}) as ProductFilterNode;

				const categoryFilters = sortTreeAlphabetically(filters[1]);

				return [brandFiltersCopy, categoryFilters];
			},
		}),
		getBrandsFilters: builder.query<IBrand[], string>({
			queryFn: async () => {
				const authToken = await getAuthToken();
				try {
					const response = await axiosInstance.get('/goods/brands/', {
						headers: {
							Authorization: `Bearer ${authToken}`,
							'Content-Type': 'application/json',
						},
					});

					if (response.status !== 200) {
						throw new Error(response.statusText);
					}

					return response;
				} catch (error) {
					throw error;
				}
			},
		}),
		getCategoriesFilters: builder.query<ICategory[], string>({
			queryFn: async (queryParams) => {
				const authToken = await getAuthToken();
				try {
					const response = await axiosInstance.get('/goods/categories', {
						params: new URLSearchParams(queryParams),
						headers: {
							Authorization: `Bearer ${authToken}`,
							'Content-Type': 'application/json',
						},
					});

					if (response.status !== 200) {
						throw new Error(response.statusText);
					}

					return response;
				} catch (error) {
					throw error;
				}
			},
		}),
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		getProductsByCategory: builder.query<IProducts, string>({
			queryFn: async (queryParams) => {
				const authToken = await getAuthToken();
				try {
					const response = await axiosInstance.get('/goods', {
						params: new URLSearchParams(queryParams),
						headers: {
							Authorization: `Bearer ${authToken}`,
							'Content-Type': 'application/json',
						},
					});

					if (response.status !== 200) {
						throw new Error(response.statusText);
					}

					return response;
				} catch (error) {
					throw error;
				}
			},
			providesTags: (result, __, id) => (result ? [orderQueryKeys.order(id)] : []),
		}),
		getPriceTypeList: builder.query({
			query: () => '/price-types',
			// !TEMP - don't need last price type
			transformResponse: (prices) => {
				if (Array.isArray(prices)) {
					return prices.slice(0, prices.length - 1);
				}

				return prices as [];
			},
		}),
		getServices: builder.query<IServicesBase, string>({
			queryFn: async (queryParams) => {
				const authToken = await getAuthToken();
				try {
					const response = await apiClient.get('/services', {
						params: queryParams,
						headers: {
							Authorization: `Bearer ${authToken}`,
							'Content-Type': 'application/json',
						},
					});

					if (response.status !== 200) {
						throw new Error(response.statusText);
					}

					return response;
				} catch (error) {
					throw error;
				}
			},
		}),
		getPaintCollectionOptionList: builder.query<SelectOption[] | string[], string>({
			// @ts-ignore
			queryFn: async () => {
				try {
					const options = await loadPaintCollectionOptions();

					return { data: options };
				} catch (error) {
					return { error };
				}
			},
		}),
		getPaintBaseToneOptionList: builder.query<SelectOption[] | string[], string>({
			// @ts-ignore
			queryFn: async () => {
				try {
					const options = await loadPaintBaseToneOptions();

					return { data: options };
				} catch (error) {
					return { error };
				}
			},
		}),
		getOrdersFiltersData: builder.query<IOrdersFilters, string>({
			queryFn: async () => {
				const authToken = await getAuthToken();
				try {
					const response = await axiosInstance.get('/orders/filters', {
						headers: {
							Authorization: `Bearer ${authToken}`,
							'Content-Type': 'application/json',
						},
					});

					if (response.status !== 200) {
						throw new Error(response.statusText);
					}

					return response;
				} catch (error) {
					throw error;
				}
			},
		}),
	}),
});

export const {
	useGetOrderByIdQuery,
	useCreateOrderMutation,
	useGetOrdersQuery,
	useUpdateOrderMutation,
	useGetProductFiltersQuery,
	useGetCategoriesFiltersQuery,
	useGetBrandsFiltersQuery,
	useDeleteOrderMutation,
	useGetPriceTypeListQuery,
	useGetPriceChangeOptionsQuery,
	useChangeProductsPriceMutation,
	useGetPaintBaseToneOptionListQuery,
	useGetPaintCollectionOptionListQuery,
	useGetProductsByCategoryQuery,
	useGetOrdersFiltersDataQuery,
	useUpdateSubOrderMutation,
	useGetClientsOrdersQuery,
	useGetServicesQuery,
} = ordersSliceApi;
