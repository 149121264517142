import clsx from 'clsx';
import Spinner from 'components/Spinner';
import VirtualizedList from 'components/VirtualizedList';
import { IBrand } from 'models/IProducts';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import AutoSizer from 'react-virtualized-auto-sizer';
import { ReactComponent as MagnifyGlassIcon } from 'static/images/searchInMenu.svg';
import { useGetBrandsFiltersQuery, useGetCategoriesFiltersQuery, useGetProductFiltersQuery } from 'store/reducers/orders/ordersSliceApi';
import { selectAllProducts } from 'store/reducers/products/selectors';

import QueuedFilterItem from '../../ClientsFilter/QueuedFilterItem';
import { brandFilterTitleMap, categoryFilterTitlesMap } from './config';
import FilterHeader from './FilterHeader';
import FilterIcon from './FilterIcon';
import styles from './styles.module.css';
import type { IProps, ProductFilterNode } from './types';
import { useProductFilter } from './useProductFilter';
import { addBrandsToRootNodes } from './utils';

const ActiveFilterIcon = () => <FilterIcon isActive />;
const InActiveFilterIcon = () => <FilterIcon />;

const ProductFilter: React.FC<IProps> = ({ className, ...restProps }) => {
	const { data } = useGetProductFiltersQuery(undefined);
	const { data: brandsFilters } = useGetBrandsFiltersQuery(undefined);
	const [brandFilters, categoryFilters] = data ?? [];

	const [searchParams, setSearchParams] = useSearchParams();
	const { data: categoriesFilters, isFetching } = useGetCategoriesFiltersQuery(searchParams.toString());
	const [searchBrand, setSearchBrand] = useState<string>('');
	const [isCategoryFilterExpanded, setIsCategoryFilterExpanded] = useState(true);
	const [actualCategories, setActualCategories] = useState(categoriesFilters);

	const handleBrandFilterChange = () => {
		const search = searchBrand.trim().toLowerCase();
		const newBrands = brandsFilters.filter((brand) => brand.title.toLowerCase().includes(search));
		return newBrands;
	};

	useEffect(() => {
		setActualCategories(categoriesFilters);
	}, [categoriesFilters]);

	// !TEMP maybe ====
	const products = useSelector(selectAllProducts(searchParams.toString()));
	const categoryFiltersWithBrandsArray = useMemo(() => {
		const children = addBrandsToRootNodes(categoryFilters.children, brandFilters.children, products);

		return { ...categoryFilters, children } as ProductFilterNode;
	}, [categoryFilters, brandFilters]);
	// !==== TEMP maybe

	const categoryFilter = useProductFilter(categoryFiltersWithBrandsArray, { urlQueryKey: 'category', clearQueryKeyOnReset: 'preselect' });
	// @ts-ignore
	const brandFilter = useProductFilter(brandFilters);

	const handleDeletePageFromSearchParams = () => {
		const newUrlSearchParams = new URLSearchParams(searchParams);
		newUrlSearchParams.delete('page');
		setSearchParams(newUrlSearchParams);
	};

	useEffect(() => {
		handleDeletePageFromSearchParams();
	}, [searchParams.get('category'), searchParams.get('brand')]);

	const updateBrandFilterSearchParams = (newBrandFilter: string | null) => {
		const newUrlSearchParams = new URLSearchParams(searchParams);

		if (searchParams.has('brand') && !newBrandFilter) {
			newUrlSearchParams.delete('brand');
			setSearchParams(newUrlSearchParams);
		} else if (!searchParams.has('brand') && newBrandFilter) {
			newUrlSearchParams.set('brand', newBrandFilter);
			setSearchParams(newUrlSearchParams);
		} else if (searchParams.has('brand') && newBrandFilter) {
			// Додано випадок для оновлення існуючого параметра 'brand'
			newUrlSearchParams.set('brand', newBrandFilter);
			setSearchParams(newUrlSearchParams);
		}
	};

	const handleCategoryFiltersSelect = () => {
		brandFilter.reset();
		categoryFilter.start();
	};

	const handleBrandFiltersSelect = () => {
		setSearchBrand('');
		categoryFilter.reset();
		brandFilter.start();
	};

	const handleBrandFilterItemClick = (brand: IBrand) => {
		// @ts-ignore
		categoryFilter.setAvailableFilters(categoryFiltersWithBrandsArray.children.filter(({ brands }) => brands.includes(brand.id)));
		updateBrandFilterSearchParams(brand.id);
		setSearchBrand('');

		brandFilter.reset();
		categoryFilter.start();
	};

	const handleBrandRootButtonClick = () => {
		categoryFilter.setAvailableFilters(categoryFiltersWithBrandsArray.children);
		updateBrandFilterSearchParams(null);
		setSearchBrand('');
		brandFilter.reset();
	};

	const onGoBackClick = () => {
		categoryFilter.setAvailableFilters(categoryFiltersWithBrandsArray.children);
		filter.reset();
		setIsCategoryFilterExpanded(true);
	};

	const categoryFilterLevel = categoryFilter.active?.length ?? null;

	const brandFilterLevel = brandFilter.active?.length ?? null;
	const filterLevel = categoryFilterLevel ?? brandFilterLevel;
	const level = filterLevel;
	const filter = categoryFilterLevel !== null ? categoryFilter : brandFilter;
	const titleMap = categoryFilterLevel !== null ? categoryFilterTitlesMap : brandFilterTitleMap;
	const headerTitle = titleMap[level] ?? titleMap['1'];

	const showRootFilterButtons = brandFilterLevel === null && categoryFilterLevel === null;
	const stack = filter.active;
	const hasItemsInStack = stack !== null && stack.length > 0;
	const showBrandFilters = filterLevel === null || categoryFilterLevel === null;
	const showSearchInput = categoryFilterLevel === null;
	const showBrandControlButton = categoryFilterLevel === null;

	return (
		<div data-product-filter className={clsx(styles.wrapper, className)} {...restProps}>
			<div data-sticky-filter className={styles.stickyContainer}>
				{isFetching && <Spinner />}

				{showRootFilterButtons && (
					<div className={styles.rootControlsWrapper}>
						<QueuedFilterItem
							isActive
							title="Категорії"
							onClick={handleCategoryFiltersSelect}
							onUnselect={handleCategoryFiltersSelect}
							icon={ActiveFilterIcon}
						/>
						<QueuedFilterItem
							isActive
							onClick={handleBrandFiltersSelect}
							onUnselect={handleBrandRootButtonClick}
							title="Виробники"
							icon={ActiveFilterIcon}
						/>
					</div>
				)}
				{!showRootFilterButtons && (
					<>
						<FilterHeader
							title={headerTitle}
							onClick={() => setIsCategoryFilterExpanded((prev) => !prev)}
							onGoBackClick={onGoBackClick}
							className={clsx(styles.header, { [styles.collapsed]: !isCategoryFilterExpanded, [styles.expandable]: !showBrandFilters })}
						/>

						{showBrandControlButton && (
							<QueuedFilterItem
								isActive
								onClick={handleBrandRootButtonClick}
								onUnselect={handleBrandRootButtonClick}
								title="Виробник"
								icon={ActiveFilterIcon}
							/>
						)}
					</>
				)}

				{showSearchInput && (
					<div className={styles.productSearchInputWrapper}>
						<label htmlFor="product-search" className="visually-hidden">
							Шукати товар
						</label>

						<MagnifyGlassIcon className={styles.icon} />

						<input
							className={clsx('text-sm-regular', styles.input)}
							value={searchBrand}
							onChange={(e) => setSearchBrand(e.currentTarget.value)}
							id="product-search"
							type="text"
							placeholder="Почніть писати бренд"
						/>
					</div>
				)}
			</div>

			{showBrandFilters && (
				<div className={clsx(styles.listsWrapper, styles.brandsList)}>
					<AutoSizer>
						{({ height, width }) => {
							return (
								<VirtualizedList
									items={handleBrandFilterChange()}
									height={height}
									width={width}
									itemSize={50 + 8}
									renderItem={({ item: brand, index, style }) => {
										const handelBrandSelect = handleBrandFilterItemClick.bind(null, brand);
										const handleBrandClick = () => {
											handelBrandSelect();
											setActualCategories([]);
										};
										const isActive = searchParams.get('brand')?.toLowerCase() === brand.id.toLowerCase();

										return (
											<div style={{ ...style, paddingBottom: '8px' }}>
												<QueuedFilterItem
													isActive={isActive}
													className={styles.brandFilterItem}
													key={brand.id + index}
													title={brand.title}
													onClick={handleBrandClick}
													onUnselect={() => updateBrandFilterSearchParams(null)}
													icon={InActiveFilterIcon}
												/>
											</div>
										);
									}}
								/>
							);
						}}
					</AutoSizer>
				</div>
			)}

			<div className={clsx(styles.listsWrapper, { [styles.hidden]: !isCategoryFilterExpanded })}>
				<ul className={styles.queueItemsList}>
					{hasItemsInStack &&
						stack.map((item) => {
							const unselectItem = () => filter.unselect(item, { bulk: true });

							return (
								<li key={item.id}>
									<QueuedFilterItem
										isActive
										// @ts-ignore
										title={item.title}
										onClick={unselectItem}
										onUnselect={unselectItem}
										icon={ActiveFilterIcon}
									/>
								</li>
							);
						})}
				</ul>
				{!showBrandFilters &&
					!isFetching &&
					actualCategories?.map((category) => {
						// @ts-ignore
						const handleBindCategory = filter.select.bind(null, category);
						const handleClick = () => {
							handleBindCategory();
							setActualCategories([]);
						};
						if ('brands' in category && searchParams.has('brand')) {
							// @ts-ignore
							if (!category.brands.includes(searchParams.get('brand'))) return null;

							return (
								<QueuedFilterItem
									title={category.title}
									onUnselect={handleClick}
									key={category.id}
									onClick={handleClick}
									icon={InActiveFilterIcon}
								/>
							);
						}

						return (
							<QueuedFilterItem
								title={category.title}
								onUnselect={handleClick}
								key={category.id}
								onClick={handleClick}
								icon={InActiveFilterIcon}
							/>
						);
					})}
			</div>
		</div>
	);
};

export default ProductFilter;
