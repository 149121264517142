import { useOrderAlertDialogue } from 'contexts/OrderAlertDialoguesProvider';
import { useEffect } from 'react';
import { orderNotificationService } from 'services/orderNotificationService';

import { useApi } from './subhooks/_useApi';
import { useBusinessLogic } from './subhooks/_useBusinessLogic';
import { useDispatcher } from './subhooks/_useDispatcher';
import { useSource } from './subhooks/_useSource';
import { useUtils } from './subhooks/_useUtils';

export const useOrderViewer = () => {
	const data = useSource();
	const utils = useUtils();
	const dispatcher = useDispatcher();
	const dialogueService = useOrderAlertDialogue();
	const api = useApi({ utils, data, dialogueService });
	const logic = useBusinessLogic({ mutate: api.mutate });

	const isProcessing = api.requestState.isLoading;
	const isFailed = api.requestState.isError;

	useEffect(() => {
		if (isFailed) {
			orderNotificationService.updatingError();
		}
	}, [isFailed]);
	dispatcher.draftOrder.onSelectChangeInPaintToningService;

	return {
		data,
		utils,
		api: {
			isProcessing,
			isFailed,
			mutate: api.mutate,
			async: api.handlers,
			isLoading: api.isLoading,
			isDeleting: api.isDeleting,
		},
		draftOrder: {
			...data.draftOrder,
			addService: dispatcher.draftOrder.addService,
			swapServices: dispatcher.draftOrder.swapServices,
			onInputChangeInProducts: dispatcher.draftOrder.onInputChangeInProducts,
			onInputChangePaintToning: dispatcher.draftOrder.onInputChangeInPaintToningService,
			onSelectChangePaintToning: dispatcher.draftOrder.onSelectChangeInPaintToningService,
			toggleOrderTypeUnsafe: dispatcher.draftOrder.toggleOrderTypeUnsafe,
			toggleOrderTypeSafe: logic.toggleOrderTypeSafe,
			closeAlertDialogue: logic.closeCurrentAlertDialogue,
			deleteItemsUnsafe: logic.deleteDraftOrderItemsUnsafe,
			deleteItemsSafe: logic.deleteDraftOrderItemsSafe,
			onQuantityChangeInService: dispatcher.draftOrder.onQuantityChangeInService,
			onPriceChangeInService: dispatcher.draftOrder.onPriceChangeInService,
			onSelectChangeServiceType: dispatcher.draftOrder.onSelectChangeServiceType,
			onSelectChangeServiceWorkType: dispatcher.draftOrder.onSelectChangeServiceWorkType,
			requestSaveSafe: logic.saveDraftOrderSafe,
			deleteInMemoryServices: dispatcher.draftOrder.deleteServicesUnsafe,
			requestSaveUnsafe: api.handlers.requestSaveDraftOrderUnsafe,
			requestReservationSafe: logic.reserveDraftOrderStatusSafe,
			requestReservationUnsafe: api.handlers.requestReserveDraftOrderUnsafe,
			syncDraftOrderWithRootSuborder: dispatcher.draftOrder.syncDraftOrderWithRootSuborder,
			updateDraftOrderData: dispatcher.draftOrder.updateDraftOrderDataMemoized,
			clearDraftOrderDataMemoized: dispatcher.draftOrder.clearDraftOrderDataMemoized,
			removeService: dispatcher.draftOrder.removeService,
		},
		suborders: {
			allSuborders: data.allSuborders,
			pureSuborders: data.pureSuborders,
			maybeDeleteSuborderIndex: data.maybeDeleteSuborderIndex,
			seeDetailsForItem: data.transfer.seeDetailsForItem,
			handlers: {
				switchCategoryTab: dispatcher.suborders.setActiveCategoryTabIndex,
				addService: dispatcher.suborders.addService,
				switchTab: logic.switchTab,
				deleteSuborderSafe: logic.deleteSuborderSafe,
				deleteSuborderUnsafe: logic.deleteSuborderUnsafe,
				appendNewTab: logic.appendNewTab,
				abortSuborderDelete: logic.abortSuborderTabDelete,
				transferProductsWithData: logic.transferProductsWithData,
				transferServicesWithData: logic.transferServicesWithData,
				transferSafe: logic.transferSafe,
				setTransferItemToSeeDetails: dispatcher.transferring.setTransferPreviewProduct,
				clearTransferItemToSeeDetails: dispatcher.transferring.clearTransferPreviewProduct,
			},
		},
		activeSuborder: {
			...data.activeSuborder,
			closeAlertDialogue: logic.abortActiveSuborderItemsDelete,
			deleteItemsUnsafe: logic.deleteItemsInSuborderUnsafe,
			deleteItemsSafe: logic.deleteSuborderItemsSafe,
			regularServices: data.activeSuborderGroupedByTypeServices.regularServices,
			paintToningServices: data.activeSuborderGroupedByTypeServices.paintToningServices,
			onQuantityChangeInService: dispatcher.activeSuborder.onQuantityChangeInService,
			onPriceChangeInService: dispatcher.activeSuborder.onPriceChangeInService,
			onSelectChangeServiceWorkType: dispatcher.activeSuborder.onSelectChangeServiceWorkType,
			onSelectChangeServiceType: dispatcher.activeSuborder.onSelectChangeServiceType,
			onInputChangeInProducts: dispatcher.activeSuborder.onInputChangeInProducts,
			selectProducts: dispatcher.transferring.selectProductsToTransfer,
		},
		paintToning: {
			suborder: data.paintToningSuborder.original,
			createSuborder: logic.createPaintToningSuborder,
		},
		stats: data.stats,
		lockedItemsList: data.lockedItemsIdsList,
		cancelSplittingSafe: logic.exitSplittingSafe,
		cancelSpittingUnsafe: logic.exitSplittingUnsafe,
		closeCurrentAlertDialogue: logic.closeCurrentAlertDialogue,
		booleans: data.booleans,
		regularSubordersLimit: data.limits.regularSubordersLimit,
		forceRerenderKey: data.rerenderKey,
	};
};
