import { useOrder } from 'hooks/orders';
import { useAppDispatch } from 'hooks/redux';
import { ISingleOrder } from 'models/IOrder';
import { useOrderContextValue } from 'pages/Order/useOrderContextValue';
import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { orderPreviewActions } from 'store/reducers/orderViewer';

import { OrderProvider } from './OrderProvider';

const OrderViewerProvider: React.FC = () => {
	const dispatch = useAppDispatch();
	const { order } = useOrder();
	const ctx = useOrderContextValue();

	useEffect(() => {
		if (Object.keys(order).length === 0) {
			return;
		}
		if (Object.keys(order).length !== 0) dispatch(orderPreviewActions.syncWithUseGetOrderQuery(order as ISingleOrder));

		return () => {
			dispatch(orderPreviewActions.clearOrderViewer());
		};
	}, [order]);
	return (
		<OrderProvider value={ctx}>
			<Outlet />
		</OrderProvider>
	);
};

export default OrderViewerProvider;
