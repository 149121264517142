/* eslint-disable react/prop-types */
import 'static/styles/style.css';

import clsx from 'clsx';
import BreadCrumbs from 'components/BreadCrumbs';
import { Breadcrumb } from 'components/BreadCrumbs/types';
import { CheckboxField } from 'components/FormComponents/Inputs/inputs';
import AutocompleteFilters from 'components/OrderCreatePageComponents/AutocompleteFilters';
import TopBarContainer from 'components/OrderCreatePageComponents/TopBarContainer';
import Payable from 'components/OrderPageComponents/Payable';
import OrderTopBar from 'components/OrderTopBar';
import Spinner from 'components/Spinner';
import { TablistRouter } from 'components/Tablist';
import { breakPoints, ORGANIZATIONS, ROUTES_URLS } from 'const';
import { useCreatingMode } from 'contexts/CreatingModeProvider';
import { OrderProvider } from 'contexts/OrderProvider';
import { useOrder } from 'hooks/orders';
import { useOrderCart } from 'hooks/useOrderCart';
import { useOrderViewer } from 'hooks/useOrderViewer';
import PageContentSkeleton from 'layouts/PageLayout/PageContentSkeleton';
import { useTransformServices } from 'pages/OrderServices/useTransformServices';
import React, { lazy, Suspense, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import MediaQuery from 'react-responsive';
import { ScrollRestoration } from 'react-router-dom';
import SaveIcon from 'static/images/save.svg';
import BidirectionalArrowsIcon from 'static/images/switch-horizontal-02.svg';
import { useGetServicesQuery } from 'store/reducers/orders/ordersSliceApi';
import { useGetInfoAboutMeQuery } from 'store/reducers/users/usersSliceApi';
import { bakeOrderBreadcrumbs } from 'utils/orders';
import { prepareUrl } from 'utils/shared';

import ControlPanel from './ControlPanel';
import OutletPanel from './OutletPanel';
import styles from './styles.module.css';
import { IProps } from './types';
import { useOrderContextValue } from './useOrderContextValue';

const RefreshActions = lazy(() => import('components/OrderCreatePageComponents/ProductSelectTopBar/RefreshActions'));
const ActionButton = lazy(() => import('layouts/PageLayout/ActionButton'));
const BottomPanel = lazy(() => import('layouts/PageLayout/BottomPanel'));
const Chip = lazy(() => import('components/Chip'));

const ProductsPanel = OutletPanel;
const ServicesPanel = OutletPanel;

const Order: React.FC<IProps> = ({}) => {
	const { id, order } = useOrder();
	const { stats, draftOrder, api } = useOrderViewer();

	const { data: servicesList } = useGetServicesQuery(undefined);

	const services = useTransformServices(draftOrder.services);

	const subServices = draftOrder.suborders.length !== 0 && draftOrder.suborders?.map((item) => useTransformServices(item.services));

	const cart = useOrderCart();
	const { creatingMode, handleChangeCreatingMode } = useCreatingMode();
	const { data: userData } = useGetInfoAboutMeQuery(undefined);
	const organizations = ORGANIZATIONS;

	const [isPaid, setIsPaid] = useState(order?.isPaid);
	const [isWithoutPayment, setIsWithoutPayment] = useState(order?.isWithoutPayment);
	const [newOrderServicesCount, setNewOrderServicesCount] = useState(0);

	const handlePaidChange = () => {
		form.setValue('isPaid', true);
		form.setValue('isWithoutPayment', false);
		setIsPaid(true);
		setIsWithoutPayment(false);
	};

	const handleWithoutPaymentChange = () => {
		form.setValue('isPaid', false);
		form.setValue('isWithoutPayment', true);
		setIsPaid(false);
		setIsWithoutPayment(true);
	};

	const getDefaultManagerValue = () => {
		if (creatingMode) {
			return { label: userData?.name, value: userData?.['1c_uuid'] };
		} else {
			return { label: order?.responsible?.name, value: order?.responsible?.['1c_uuid'] };
		}
	};

	const getDefaultStockValue = () => {
		if (!creatingMode) {
			return { label: order?.stock?.title, value: order?.stock?.id };
		} else {
			return { label: userData?.stock?.title, value: userData?.stock?.id };
		}
	};

	const form = useForm({
		defaultValues: {
			client: creatingMode ? cart.createOrderDataState.client : order.client,
			stock: null,
			responsible: null,
			organization: organizations[0],
			contract: order?.contract,
			isPaid: order?.isPaid,
			isWithoutPayment: order?.isWithoutPayment,
		},
	});

	useEffect(() => {
		form.setValue('responsible', getDefaultManagerValue());
		form.setValue('stock', getDefaultStockValue());
		if (order) {
			form.setValue('client', order.client);
		}
	}, [creatingMode, userData, order]);

	const ctxValue = useOrderContextValue();

	useEffect(() => {
		if (!id) {
			handleChangeCreatingMode(true);
		} else {
			handleChangeCreatingMode(false);
			cart.clearCreateOrderData();
		}
	}, [id, order]);

	useEffect(() => {
		const unwatch = form.watch((values) => {
			updateNewOrder(values);
		});
		unwatch;
	}, [form]);

	const updateNewOrder = (data) => {
		cart.updateDataForCreatingMode(data);
	};

	useEffect(() => {
		const unwatch = form.watch((values) => {
			updateNewOrder({ client: { ...cart.createOrderDataState.client }, products: cart.createOrderDataState.products, ...values });
		});
		unwatch;
	}, [form]);

	useEffect(() => {
		const unwatch = form.watch((values) => {
			draftOrder.updateDraftOrderData(values);
		});
		unwatch;
	}, [form]);

	const crumbs: Breadcrumb[] = [{ label: 'Заявки', href: prepareUrl(ROUTES_URLS.ORDERS) }, bakeOrderBreadcrumbs(order)];

	const hasProductsInOrder = !!draftOrder.products.length;

	const { totalPrice } = stats;

	const productsCount = creatingMode ? cart.createOrderDataState.products?.length : draftOrder.productsCount;
	const servicesCount = creatingMode ? newOrderServicesCount : draftOrder.regularServicesCount + draftOrder.paintToningServicesCount;

	useEffect(() => {
		setNewOrderServicesCount(cart.createOrderDataState.services?.lenght);
	}, [cart.createOrderDataState]);

	useEffect(() => {
		if (services !== undefined && services.length !== 0) {
			draftOrder.swapServices(services);
		}
	}, [servicesList, services]);

	useEffect(() => {
		if (draftOrder.suborders.length > 0 && subServices.lenght > 0) {
			subServices.forEach((item, index) => {
				draftOrder.swapServices(item, 'suborder', index);
			});
		}
	}, [servicesList, subServices]);

	const requestLogistics = () => {
		alert('Заявка на логістику надіслана');
	};

	const handleTabChange = () => {
		ctxValue.exitChangePriceMode();
	};

	const rootPath = creatingMode ? prepareUrl(ROUTES_URLS.NEW_ORDER) : prepareUrl(ROUTES_URLS.ORDER, { id });

	const shouldRenderTopElements = !ctxValue.isFullScreenMode;

	return (
		<Suspense fallback={<PageContentSkeleton />}>
			<FormProvider {...form}>
				<OrderProvider value={ctxValue}>
					<div className="main-wrapper">
						<div className={clsx('container', styles.container)}>
							<BreadCrumbs crumbs={crumbs} />

							{shouldRenderTopElements && (
								<>
									{hasProductsInOrder && (
										<>
											<MediaQuery maxWidth={breakPoints.MOBILE - 1}>
												<div className={styles.refreshActionsWrapper}>
													<CheckboxField
														label="З оплатою"
														name="isPaid"
														className={styles.checkboxField}
														defaultChecked={isPaid}
														onChange={handlePaidChange}
													/>
													<CheckboxField
														label="Без оплати"
														name="isWithoutPayment"
														className={styles.checkboxField}
														defaultChecked={isWithoutPayment}
														onChange={handleWithoutPaymentChange}
													/>

													<RefreshActions />
												</div>
											</MediaQuery>

											<MediaQuery minWidth={breakPoints.MOBILE}>
												<OrderTopBar onRequestLogistics={requestLogistics} />
											</MediaQuery>

											<div className={styles.totalPriceWrapper}>
												<Payable price={totalPrice} />

												<div className={styles.chipsWrapper}>
													<Chip title={`Вага - ${order?.weight} кг`} />
													<Chip title={`Об’єм - ${order?.volume} м³`} />
												</div>

												<MediaQuery minWidth={breakPoints.MOBILE}>
													<CheckboxField
														label="З оплатою"
														name="isPaid"
														className={styles.checkboxField}
														defaultChecked={isPaid}
														onChange={handlePaidChange}
													/>
													<CheckboxField
														label="Без оплати"
														name="isWithoutPayment"
														className={styles.checkboxField}
														defaultChecked={isWithoutPayment}
														onChange={handleWithoutPaymentChange}
													/>

													{/* <ToggleOrderTypeGate>
														<ToggleOrderType className={styles.checkboxField} />
													</ToggleOrderTypeGate> */}
												</MediaQuery>
											</div>
										</>
									)}

									<TopBarContainer className={clsx(styles.topBarContainer, { [styles.emptyOrder]: !hasProductsInOrder })}>
										<AutocompleteFilters />
									</TopBarContainer>

									{hasProductsInOrder && (
										<div className={styles.bottomPanelWrapper}>
											<MediaQuery maxWidth={breakPoints.MOBILE - 1}>
												<BottomPanel className={styles.bottomPanel}>
													<div>
														<ActionButton
															text="Оновити залишки"
															variant="rounded"
															background="var(--primary-500)"
															icon={BidirectionalArrowsIcon}
														/>
														<ActionButton
															text="Зберегти заявку"
															variant="rounded"
															background="var(--pink)"
															icon={SaveIcon}
														/>
													</div>
												</BottomPanel>
											</MediaQuery>
										</div>
									)}
								</>
							)}

							<div className={styles.pageContent}>
								<TablistRouter rootPath={rootPath} slot={<ControlPanel />} onTabChange={handleTabChange}>
									<ProductsPanel href={rootPath} itemsCount={productsCount} title="Додані товари" />
									<ServicesPanel href={ROUTES_URLS.ORDER_SERVICES} itemsCount={servicesCount} title="Послуги" />
								</TablistRouter>
							</div>

							{api.isProcessing && <Spinner />}
						</div>

						<ScrollRestoration
							getKey={(location) => {
								const paths = [rootPath, `${rootPath}/services`];
								return paths.includes(location.pathname) ? location.pathname : location.key;
							}}
						/>
					</div>
				</OrderProvider>
			</FormProvider>
		</Suspense>
	);
};

export default Order;
