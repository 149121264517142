import clsx from 'clsx';
import Button from 'components/Button';
import CheckboxBase from 'components/CheckboxBase';
import Drawer from 'components/Drawer';
import Input from 'components/Input';
import Modal from 'components/Modal';
import ModalHeader from 'components/Modal/ModalHeader';
import OrderAdditionalDrawer from 'components/OrderPageComponents/OrderAdditionalDrawer';
import Spinner from 'components/Spinner';
import { useOrderStatus } from 'hooks/orders';
import { useOrderViewer } from 'hooks/useOrderViewer';
import React, { Suspense, useState } from 'react';
import File from 'static/images/file-attachment-02.svg';
import Lock from 'static/images/lock.svg';
import Plus from 'static/images/plusBig.svg';
import Save from 'static/images/save-double.svg';
import Truck from 'static/images/truck-white.svg';
// @ts-ignore
import PDF from 'static/pdf/CP.pdf';
import { checkIfOrderIsReserved } from 'utils/shared';

import styles from './style.module.css';
import { IProps } from './types';

const OrderTopBar: React.FC<IProps> = ({ onRequestLogistics }) => {
	const [isOpen, setIsOpen] = useState(false);
	const [openModal, setOpenModal] = useState(false);
	const [proposal, setProposal] = useState({ title: '', text: '', checked: false });

	// const isTablet = useMediaQuery({ query: `(min-width: ${breakPoints.MOBILE}px) and (max-width: 845px)` });
	// const navigate = useNavigate();

	const { draftOrder } = useOrderViewer();
	const orderStatus = useOrderStatus();
	const isReservedOrder = checkIfOrderIsReserved(orderStatus);
	const handleOpenDrawer = () => {
		setIsOpen(true);
	};

	const handleCloseDrawer = () => {
		setIsOpen(false);
	};

	const handleOpenPDF = () => {
		window.open(PDF, '_blank');
	};

	const handleOpenCreateComProposal = (e) => {
		e.stopPropagation();
		setOpenModal(true);
	};

	const handleOrderSave = async (event: React.MouseEvent<HTMLButtonElement>) => {
		event.stopPropagation();

		draftOrder.requestSaveSafe();
		// await cart.saveMany();
	};
	const handleOrderReservation = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.stopPropagation();

		draftOrder.requestReservationSafe();
	};

	// const handleProposeCommercial = () => {
	// 	navigate(prepareUrl(ROUTES_URLS.COMMERCIAL_PROPOSAL));
	// };

	// const logisticButtonTitle = isTablet ? '' : 'Заявка на логістику';
	// const reservationButtonTitle = isTablet ? '' : 'В резерв';
	// const saveButtonTitle = isTablet ? '' : 'Зберегти заявку';
	// const commercialProposalButtonTitle = isTablet ? '' : 'Ком. пропозиція';

	return (
		<>
			<div className={styles.topBarWrapper}>
				<Button
					onClick={onRequestLogistics}
					icon={Truck}
					variant="rounded"
					background={'#2E90FA'}
					hoverBg="#000"
					className={styles.rounded}
				/>
				<Button onClick={handleOrderSave} icon={Save} variant="rounded" background={'#d71b87'} hoverBg="#000" className={styles.rounded} />
				<Button
					variant="rounded"
					background={'#2E90FA'}
					hoverBg="#000"
					onClick={(e) => handleOpenCreateComProposal(e)}
					className={styles.rounded}
					icon={File}
				/>

				<Button
					background="var(--error-50)"
					onClick={handleOrderReservation}
					icon={Lock}
					variant="rounded"
					className={clsx(styles.reserv, styles.rounded, { [styles.active]: isReservedOrder })}
				/>
				<Button
					onClick={handleOpenDrawer}
					icon={Plus}
					variant="rounded"
					background={'var(--primary-500)'}
					hoverBg="#000"
					className={styles.btn}
				/>
			</div>

			<Suspense fallback={<Spinner />}>
				<Modal open={openModal} onClose={() => setOpenModal(false)} width="640px" maxWidth="unset" className={styles.labels}>
					<ModalHeader title={'Створити комерційну пропозицію'} onXCloseClick={() => setOpenModal(false)} />
					<span className={styles.divider}></span>
					<Input
						label="Назва пропозиції"
						type={'text'}
						placeholder="Напишіть назву документу"
						className={clsx(styles.nameInput, styles.input)}
						value={proposal.title}
						setValue={(title) => setProposal({ ...proposal, title })}
					/>
					<Input
						label="Текст пропозиції"
						type={'text'}
						placeholder="Не більше 300 символів"
						className={clsx(styles.textInput, styles.input)}
						value={proposal.text}
						setValue={(text) => setProposal({ ...proposal, text: text })}
					/>
					<div className={styles.footerWrapper}>
						<CheckboxBase checked={proposal.checked} setChecked={(checked) => setProposal({ ...proposal, checked })} label="Фото" />
						<Button variant="default" text="Створити КП" background={'var(--pink)'} icon={Save} onClick={handleOpenPDF} />
					</div>
				</Modal>
			</Suspense>

			<Suspense fallback={<Spinner />}>
				{isOpen && (
					<Drawer open={isOpen} title={'Додати'} onClose={handleCloseDrawer}>
						<OrderAdditionalDrawer />
					</Drawer>
				)}
			</Suspense>
		</>
	);
};

export default OrderTopBar;
