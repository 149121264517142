import clsx from 'clsx';
import Spinner from 'components/Spinner';
import { useCreatingMode } from 'contexts/CreatingModeProvider';
import { useOrderCart } from 'hooks/useOrderCart';
import { useOrderViewer } from 'hooks/useOrderViewer';
import React from 'react';
import { ReactComponent as TrashIcon } from 'static/images/trash.svg';

import styles from './styles.module.css';
import type { IProps } from './types';

const ServiceDeleteButton: React.FC<IProps> = ({ item, className }) => {
	const { draftOrder, api, utils } = useOrderViewer();
	const { creatingMode } = useCreatingMode();
	const cart = useOrderCart();

	const handleServiceDelete = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.stopPropagation();
		if (creatingMode) {
			cart.deleteServiceInNewOrder(item.id);
		} else {
			if (utils.isOnSuborderSplittingPage()) {
				draftOrder.removeService(item.id, 'suborder');
			} else {
				draftOrder.removeService(item.id);
			}
		}

		// source.deleteItemsSafe([item]);
	};

	return (
		<>
			<button type="button" onClick={handleServiceDelete} className={clsx(styles.button, className)}>
				<TrashIcon className={styles.icon} />
			</button>

			{api.isProcessing && <Spinner />}
		</>
	);
};

export default ServiceDeleteButton;
