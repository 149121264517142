/* eslint-disable react/prop-types */
import TableCellInput from 'components/OrderCreatePageComponents/TableCellInput';
import { LockedProductQuantityGate } from 'components/OrderPageComponents/(LockerGates)/LockedProductQuantityGate';
import OrderExtraActionButton from 'components/OrderPageComponents/OrderExtraActionButton';
import { HeaderCellContent, RegularCellContent } from 'components/Table/TableComponents/ContentOrientedCells';
import { HeaderCell, RegularCell } from 'components/Table/TableComponents/SpacingOrientedCells';
import { breakPoints } from 'const';
import { useOrder } from 'hooks/orders';
import { useAdjustableColumns } from 'hooks/useAdustableColumns';
import { adjustableColumnsIcons } from 'pages/CreateOrder/icons';
import React, { ChangeEvent, useMemo } from 'react';
import { useMediaQuery } from 'react-responsive';
import { numberify, toPrecise } from 'utils/shared';

export type UseOrderColumnsProps = {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	onInputChange?: (value: any, property: string, formatter?: (...any: any[]) => any) => (event: ChangeEvent<HTMLInputElement>) => void;
	adjustSaveKey?: string;
	omit?: string[];
	rerenderKey?: unknown;
};

const noop = () => () => {};
const emptyArray = [];

export const useOrderColumns = (props?: UseOrderColumnsProps) => {
	const isTablet = useMediaQuery({ query: `(min-width: ${breakPoints.MOBILE}px) and (max-width: ${breakPoints.DESKTOP - 1}px)` });
	const isDesktop = useMediaQuery({ query: `(min-width: ${breakPoints.DESKTOP}px)` });
	const isMobile = useMediaQuery({ query: `(max-width: ${breakPoints.MOBILE - 1}px)` });
	const cellWidth = 'fit-content';
	const { order } = useOrder();

	const findReserveByStockId = (reserves) => {
		const reserveAmount = reserves?.find((reserve) => reserve?.stock?.id === order?.stock?.id);
		return reserveAmount ? reserveAmount.amount : null;
	};

	const findSalePrice = (prices) => {
		const salePrice = prices?.find((price) => price.typePrice.id === 'a129be59-31f8-4bee-9f3b-cf4e76495df8');
		return salePrice;
	};

	const findGoodPrice = (prices) => {
		const goodPrice = prices?.find((price) => price.typePrice.id === 'e4ea81a3-8307-11ed-b8b4-932465dbe65a');
		return goodPrice;
	};

	const getSumForProduct = (amount, price) => {
		return amount * price;
	};

	const { onInputChange = noop, adjustSaveKey, omit = emptyArray, rerenderKey = 'rerenderKey' } = props ?? {};

	const initialColumns = useMemo(
		() =>
			[
				{
					Header: ({ isAdjustable }) => {
						if (isAdjustable) return <>Id</>;

						return (
							<HeaderCell>
								<HeaderCellContent value="" />
							</HeaderCell>
						);
					},
					accessor: 'id',
					isAdjustable: true,
					width: 56,
					Cell: ({ row }) => (
						<RegularCell>
							<RegularCellContent>{Number(row.id) + 1}.</RegularCellContent>
						</RegularCell>
					),
					mobile: false,
					icon: <adjustableColumnsIcons.Uuid />,
					hidden: false,
				},
				{
					Header: ({ column, rows, isAdjustable }) => {
						const title = 'Код товару';

						if (isAdjustable) {
							return <>{title}</>;
						}

						return (
							<HeaderCell>
								<HeaderCellContent withIndeterminate column={column} disabled={rows.length < 2} value={title} />
							</HeaderCell>
						);
					},
					accessor: 'code',
					isAdjustable: true,
					Cell: ({ row }) => {
						const code = row.original.code;

						return (
							<RegularCell>
								<RegularCellContent>{code}</RegularCellContent>
							</RegularCell>
						);
					},
					sortable: false,
					width: cellWidth,
					icon: <adjustableColumnsIcons.FinalCost />,
					hidden: false,
				},

				...(isTablet
					? [
							{
								Header: ({ isAdjustable }) => {
									const title = 'Товар';

									if (isAdjustable) return <>{title}</>;

									return (
										<HeaderCell>
											<HeaderCellContent textAlign="center" value={title} />
										</HeaderCell>
									);
								},
								accessor: 'brand',
								isAdjustable: true,
								width: 300,
								Cell: ({ row }) => (
									<RegularCell pr="12px" gap="8px">
										<RegularCellContent className="tablet-cell">
											<span>{row.values.brand?.title}</span>
											<span>{row.original?.title}</span>
										</RegularCellContent>

										<OrderExtraActionButton product={row.original} />
									</RegularCell>
								),
								mobile: false,
								icon: <adjustableColumnsIcons.Product />,
								hidden: false,
							},
					  ]
					: [
							{
								Header: ({ column, rows, isAdjustable }) => {
									const title = 'Товар';

									if (isAdjustable) return <>{title}</>;

									return (
										<HeaderCell>
											<HeaderCellContent
												{...((isDesktop || isTablet) && { justify: 'flex-start' })}
												forceSortableMobile
												withIndeterminate
												sortable
												column={column}
												disabled={rows.length < 2}
												value={title}
											/>
										</HeaderCell>
									);
								},
								accessor: 'title',
								isAdjustable: true,
								width: 300,
								Cell: ({ row }) => {
									if (!isMobile) {
										return (
											<RegularCell pr="12px" gap="8px">
												<RegularCellContent justify="flex-start" value={row.values.title} />

												<OrderExtraActionButton product={row.original} />
											</RegularCell>
										);
									}

									return (
										<RegularCell gap="8px">
											<RegularCellContent className="stack">
												<span>{row.original?.title}</span>
												<span>{row.original?.brand}</span>
											</RegularCellContent>

											<OrderExtraActionButton product={row.original} />
										</RegularCell>
									);
								},
								mobile: false,
								icon: <adjustableColumnsIcons.Product />,
								hidden: false,
							},
							{
								Header: ({ column, rows, isAdjustable }) => {
									const title = 'Бренд';

									if (isAdjustable) return <>{title}</>;

									return (
										<HeaderCell>
											<HeaderCellContent
												{...((isDesktop || isTablet) && { justify: 'flex-start' })}
												forceSortableMobile
												withIndeterminate
												sortable
												column={column}
												disabled={rows.length < 2}
												value={title}
											/>
										</HeaderCell>
									);
								},
								accessor: 'brand',
								isAdjustable: true,
								width: 143,
								Cell: ({ row }) => (
									<RegularCell>
										<RegularCellContent
											{...((isDesktop || isTablet) && { justify: 'flex-start' })}
											value={row.values.brand?.title}
										/>
									</RegularCell>
								),
								mobile: false,
								icon: <adjustableColumnsIcons.Brand />,
								hidden: false,
							},
					  ]),
				{
					Header: ({ isAdjustable }) => {
						const title = 'К-ть';

						if (isAdjustable) return <>{title}</>;

						return (
							<HeaderCell {...((isDesktop || isTablet) && { justify: 'center' })}>
								<HeaderCellContent value={title} />
							</HeaderCell>
						);
					},
					accessor: 'amount',
					isAdjustable: true,
					width: cellWidth,
					Cell: ({ row }) => {
						if (!isMobile) {
							return (
								<RegularCell justify="center">
									<RegularCellContent>
										<LockedProductQuantityGate item={row.original} rowIndex={row.id}>
											<TableCellInput
												type="tel"
												key={String(rerenderKey)}
												defaultValue={toPrecise(numberify(row.original.amount), 2)}
												onChange={onInputChange(row.original.id, 'amount')}
												onBlur={onInputChange(row.original.id, 'amount', formatter)}
												width="100px"
											/>
										</LockedProductQuantityGate>
									</RegularCellContent>
								</RegularCell>
							);
						}
						return (
							<RegularCell>
								<RegularCellContent className="quantity-cell">
									<TableCellInput
										type="tel"
										key={String(rerenderKey)}
										defaultValue={toPrecise(numberify(row.original.amount), 2)}
										onChange={onInputChange(row.original.id, 'amount')}
										onBlur={onInputChange(row.original.id, 'amount', formatter)}
										width="100px"
									/>
									<span className="color-grey-400 measure-unit">{row.original?.measureUnit?.label}</span>
									<button type="button" className="text-sx-regular color-primary-600">
										Змінити
									</button>
								</RegularCellContent>
							</RegularCell>
						);
					},
					mobile: false,
					icon: <adjustableColumnsIcons.Quantity />,
					hidden: false,
				},
				{
					Header: ({ isAdjustable }) => {
						const title = 'Од-ці';

						if (isAdjustable) return <>{title}</>;

						return (
							<HeaderCell {...((isDesktop || isTablet) && { justify: 'center' })}>
								<HeaderCellContent value={title} />
							</HeaderCell>
						);
					},
					accessor: 'unit',
					isAdjustable: true,
					width: cellWidth,
					Cell: ({ row }) => {
						return (
							<RegularCell {...((isDesktop || isTablet) && { justify: 'center' })}>
								<RegularCellContent textAlign="center">{row.original.unit?.title}.</RegularCellContent>
							</RegularCell>
						);
					},
					skip: true,
					mobile: false,
					icon: <adjustableColumnsIcons.MeasureUnit />,
					hidden: false,
				},
				{
					Header: ({ column, rows, isAdjustable }) => {
						const title = 'Ціна DIY';

						if (isAdjustable) return <>{title}</>;

						return (
							<HeaderCell {...((isDesktop || isTablet) && { justify: 'center' })}>
								<HeaderCellContent sortable withIndeterminate column={column} disabled={rows.length < 2} value={title} />
							</HeaderCell>
						);
					},
					accessor: 'price',
					isAdjustable: true,
					width: cellWidth,
					Cell: ({ row }) => {
						return (
							<RegularCell {...((isDesktop || isTablet) && { justify: 'center' })}>
								<RegularCellContent>
									<TableCellInput
										type="tel"
										key={String(rerenderKey)}
										defaultValue={toPrecise(numberify(row.original.price || row.original?.prices?.[0]?.price), 2)}
										pattern="[0-9]"
										onChange={onInputChange(row.original.id, 'price')}
										onBlur={onInputChange(row.original.id, 'price', formatter)}
									/>
								</RegularCellContent>
							</RegularCell>
						);
					},
					mobile: false,
					icon: <adjustableColumnsIcons.Price />,
					hidden: false,
				},
				{
					Header: ({ column, rows, isAdjustable }) => {
						const title = 'Ост. ц.';

						if (isAdjustable) return <>{title}</>;

						return (
							<HeaderCell {...((isDesktop || isTablet) && { justify: 'center' })}>
								<HeaderCellContent sortable withIndeterminate column={column} disabled={rows.length < 2} value={title} />
							</HeaderCell>
						);
					},
					accessor: 'finalPrice',
					isAdjustable: true,
					width: cellWidth,

					Cell: ({ row }) => {
						const price = toPrecise(row.original.sum + row.original.sumTax);

						return (
							<RegularCell {...((isDesktop || isTablet) && { justify: 'center' })}>
								<RegularCellContent className="highlighter-100" value={price} textAlign="center" />
							</RegularCell>
						);
					},
					mobile: false,
					icon: <adjustableColumnsIcons.FinalCost />,
					hidden: false,
				},
				{
					Header: ({ column, rows, isAdjustable }) => {
						const title = 'Сума';

						if (isAdjustable) return <>{title}</>;

						return (
							<HeaderCell {...((isDesktop || isTablet) && { justify: 'center' })}>
								<HeaderCellContent sortable withIndeterminate column={column} disabled={rows.length < 2} value={title} />
							</HeaderCell>
						);
					},
					accessor: 'sum',
					isAdjustable: true,
					width: cellWidth,

					Cell: ({ row }) => {
						return (
							<RegularCell {...((isDesktop || isTablet) && { justify: 'center' })}>
								<RegularCellContent
									value={
										row.original.sum ||
										getSumForProduct(row.original.price || row.original?.prices?.[0]?.price, row.original.amount)
									}
									justify="center"
								/>
							</RegularCell>
						);
					},
					mobile: false,
					icon: <adjustableColumnsIcons.TotalPrice />,
					hidden: false,
				},
				{
					Header: ({ column, rows, isAdjustable }) => {
						const title = 'Резерв';

						if (isAdjustable) return <>{title}</>;

						return (
							<HeaderCell {...((isDesktop || isTablet) && { justify: 'center' })}>
								<HeaderCellContent
									sortable
									withIndeterminate
									column={column}
									disabled={rows.length < 2}
									value={title}
									justify="center"
								/>
							</HeaderCell>
						);
					},
					accessor: 'isReserved',
					isAdjustable: true,
					width: cellWidth,
					Cell: ({ row }) => (
						<RegularCell {...((isDesktop || isTablet) && { justify: 'center' })}>
							<RegularCellContent
								className="highlighter-200"
								value={findReserveByStockId(row.original.reserves) || '—'}
								justify="center"
							/>
						</RegularCell>
					),
					mobile: false,
					icon: <adjustableColumnsIcons.InReserve />,
					hidden: false,
				},
				{
					Header: ({ column, rows, isAdjustable }) => {
						const title = 'Залишок';

						if (isAdjustable) return <>{title}</>;

						return (
							<HeaderCell {...((isDesktop || isTablet) && { justify: 'center' })}>
								<HeaderCellContent
									sortable
									withIndeterminate
									column={column}
									disabled={rows.length < 2}
									value={title}
									justify="center"
								/>
							</HeaderCell>
						);
					},
					accessor: 'inStock',
					isAdjustable: true,
					width: cellWidth,
					Cell: ({ row }) => (
						<RegularCell {...((isDesktop || isTablet) && { justify: 'center' })}>
							<RegularCellContent value={findReserveByStockId(row.original.leftovers) || '—'} justify="center" />
						</RegularCell>
					),
					mobile: false,
					icon: <adjustableColumnsIcons.Remaining />,
					hidden: false,
				},
				{
					Header: ({ column, rows, isAdjustable }) => {
						const title = 'Вільний залишок';

						if (isAdjustable) return <>{title}</>;

						return (
							<HeaderCell {...(isDesktop && { justify: 'center' })}>
								<HeaderCellContent
									sortable
									withIndeterminate
									column={column}
									disabled={rows.length < 2}
									value={title}
									justify="center"
								/>
							</HeaderCell>
						);
					},
					accessor: 'freeLeftovers',
					isAdjustable: true,
					width: cellWidth,
					Cell: ({ row }) => (
						<RegularCell {...(isDesktop && { justify: 'center' })}>
							<RegularCellContent value={findReserveByStockId(row.original.freeLeftovers) || '—'} justify="center" />
						</RegularCell>
					),
					mobile: false,
					icon: <adjustableColumnsIcons.AvailableBalance />,
					hidden: false,
				},
				{
					Header: ({ column, rows, isAdjustable }) => {
						const title = 'Відхилення %';

						if (isAdjustable) return <>{title}</>;

						return (
							<HeaderCell {...(isDesktop && { justify: 'center' })}>
								<HeaderCellContent
									sortable
									withIndeterminate
									column={column}
									disabled={rows.length < 2}
									value={title}
									justify="center"
								/>
							</HeaderCell>
						);
					},
					accessor: 'deviation',
					isAdjustable: true,
					width: cellWidth,
					Cell: ({ row }) => (
						<RegularCell {...(isDesktop && { justify: 'center' })}>
							<RegularCellContent value={row.original.deviation} justify="center" />
						</RegularCell>
					),
					mobile: false,
					icon: <adjustableColumnsIcons.Deviation />,
					hidden: false,
				},
				{
					Header: ({ column, rows, isAdjustable }) => {
						const title = 'Ручна знижка%';

						if (isAdjustable) return <>{title}</>;

						return (
							<HeaderCell {...(isDesktop && { justify: 'center' })}>
								<HeaderCellContent
									sortable
									withIndeterminate
									column={column}
									disabled={rows.length < 2}
									value={title}
									justify="center"
								/>
							</HeaderCell>
						);
					},
					accessor: 'manualDiscount',
					isAdjustable: true,
					width: cellWidth,
					Cell: () => (
						<RegularCell {...(isDesktop && { justify: 'center' })}>
							<RegularCellContent value={'—'} justify="center" />
						</RegularCell>
					),
					mobile: false,
					icon: <adjustableColumnsIcons.ManualDiscount />,
					hidden: false,
				},
				{
					Header: ({ column, rows, isAdjustable }) => {
						const title = 'Вигідна ціна';

						if (isAdjustable) return <>{title}</>;

						return (
							<HeaderCell {...(isDesktop && { justify: 'center' })}>
								<HeaderCellContent
									sortable
									withIndeterminate
									column={column}
									disabled={rows.length < 2}
									value={title}
									justify="center"
								/>
							</HeaderCell>
						);
					},
					accessor: 'favorablePrice',
					isAdjustable: true,
					width: cellWidth,
					Cell: ({ row }) => (
						<RegularCell {...(isDesktop && { justify: 'center' })}>
							<RegularCellContent value={findGoodPrice(row.original.prices)?.price || '—'} justify="center" />
						</RegularCell>
					),
					mobile: false,
					icon: <adjustableColumnsIcons.FavorablePrice />,
					hidden: false,
				},
				{
					Header: ({ column, rows, isAdjustable }) => {
						const title = 'Ціна акційна';

						if (isAdjustable) return <>{title}</>;

						return (
							<HeaderCell {...(isDesktop && { justify: 'center' })}>
								<HeaderCellContent
									sortable
									withIndeterminate
									column={column}
									disabled={rows.length < 2}
									value={title}
									justify="center"
								/>
							</HeaderCell>
						);
					},
					accessor: 'promotionPrice',
					isAdjustable: true,
					width: cellWidth,
					Cell: ({ row }) => (
						<RegularCell {...(isDesktop && { justify: 'center' })}>
							<RegularCellContent value={findSalePrice(row.original.prices)?.price || '—'} justify="center" />
						</RegularCell>
					),
					mobile: false,
					icon: <adjustableColumnsIcons.PromotionPrice />,
					hidden: false,
				},
				{
					Header: ({ column, rows, isAdjustable }) => {
						const title = 'Рентабельність';

						if (isAdjustable) return <>{title}</>;

						return (
							<HeaderCell {...(isDesktop && { justify: 'center' })}>
								<HeaderCellContent
									sortable
									withIndeterminate
									column={column}
									disabled={rows.length < 2}
									value={title}
									justify="center"
								/>
							</HeaderCell>
						);
					},
					accessor: 'profitability',
					isAdjustable: true,
					width: cellWidth,
					Cell: ({ row }) => (
						<RegularCell {...(isDesktop && { justify: 'center' })}>
							<RegularCellContent value={row.original.profitability} justify="center" />
						</RegularCell>
					),
					mobile: false,
					icon: <adjustableColumnsIcons.Points />,
					hidden: false,
				},
			].filter(({ accessor }) => !omit.includes(accessor)),
		[isTablet, isDesktop, omit, isMobile, rerenderKey],
	);
	const { columns, adjust } = useAdjustableColumns(initialColumns, { saveConfigKey: adjustSaveKey });

	const pinColumns = useMemo(() => (isTablet ? ['selection', 'id', 'brand', 'code'] : ['selection', 'id', 'title', 'brand', 'code']), [isTablet]);

	return {
		initialColumns,
		pinColumns,
		columns,
		adjust,
	};
};

function formatter(value: string) {
	const formattedValue = numberify(value) || 1;

	return toPrecise(formattedValue, 2);
}
