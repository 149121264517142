import { useCreatingMode } from 'contexts/CreatingModeProvider';
import { useOrderCart } from 'hooks/useOrderCart';
import { useOrderViewer } from 'hooks/useOrderViewer';
import React, { Fragment } from 'react';
import { numberify, toPrecise } from 'utils/shared';

import styles from './styles.module.css';
import { ServiceInputFieldProps } from './types';

function formatter(value: string) {
	const formattedValue = numberify(value) || 1;

	return toPrecise(formattedValue, 2);
}

const ServiceInputField: React.FC<ServiceInputFieldProps> = ({ id, value, disabled }) => {
	const { activeSuborder, draftOrder, utils, forceRerenderKey } = useOrderViewer();
	const { creatingMode } = useCreatingMode();
	const cart = useOrderCart();
	const source = utils.isOnSuborderSplittingPage() ? activeSuborder : draftOrder;

	const handler = creatingMode ? cart.changeServicePriceInNewService : source.onPriceChangeInService;

	return (
		<Fragment key={forceRerenderKey}>
			<input
				disabled={disabled}
				className={styles.inputField}
				value={value}
				type="tel"
				onChange={handler(id)}
				onBlur={handler(id, formatter)}
			/>
		</Fragment>
	);
};

export default ServiceInputField;
