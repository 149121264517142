import { createDraftSafeSelector } from '@reduxjs/toolkit';
import { Products } from 'models/IOrder';
import type { RootState } from 'store';

const rootState = (state: RootState) => state;
const createOrderSliceStateSelector = createDraftSafeSelector(rootState, (state) => state.order);

// ---------> selects product user has picked to add to the current order <--------- //
export const selectPreviewedProduct = createDraftSafeSelector(createOrderSliceStateSelector, (state) => state?.previewProduct ?? ({} as Products));
export const selectConfigurationToolView = createDraftSafeSelector(createOrderSliceStateSelector, (state) => state?.view);
export const selectIsModalOpen = createDraftSafeSelector(createOrderSliceStateSelector, (state) => state.isModalOpen);
export const createOrderData = createDraftSafeSelector(createOrderSliceStateSelector, (state) => state.createOrderData);
export const selectProductsInOrderCart = createDraftSafeSelector(
	createOrderSliceStateSelector,
	(state) => Object.values(state?.products ?? {}) as Products[],
);
export const selectRawProductsInOrderCart = createDraftSafeSelector(createOrderSliceStateSelector, (state) => state?.products);
