import type { IProps as NavbarProps } from 'components/NavBarMain/types';
import { ROUTES_URLS } from 'const';
import { useMemo } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { loadClientsAsOptions, loadProductsAsOptions } from 'services/optionLoaders';
import { useCreateOrderMutation, useUpdateOrderMutation } from 'store/reducers/orders/ordersSliceApi';
import { selectSearchBy } from 'store/reducers/search/selectors';
import { prepareUrl } from 'utils/shared';
import { assertNever } from 'utils/type-guards';

import { useClientsTableData } from './clients';
import { useOrder } from './orders';
import { useAppSelector } from './redux';

type UseMainSearchFlow = 'home:new-order' | 'order:empty-order';
type UseMainSearchProps = { flow: UseMainSearchFlow };
type DtoCreatorFunction<TData> = (obj: Record<string, unknown>) => TData;

export const useMainSearch = ({ flow }: UseMainSearchProps) => {
	const navigate = useNavigate();
	const searchBy = useAppSelector(selectSearchBy);
	const [searchParams] = useSearchParams();
	const { asyncHandler, state, prepareDTO } = useGetMainSearchFlowAsyncHandlers(flow);

	// ! TEMP
	const { tableData } = useClientsTableData();

	const mainSearchConfig: NavbarProps['mainSearchProps'] = useMemo(() => {
		if (searchBy === 'client') {
			return {
				loadAsyncData: loadClientsAsOptions,
				onItemClick: async (client) => {
					const pickedClient = tableData?.find(({ id }) => id === client['Id']);
					// @ts-ignore
					const newOrder = prepareDTO((order) => ({ ...order, client: { ...order.client, ...pickedClient } }));
					const order = await asyncHandler(newOrder).unwrap();

					const url = prepareUrl(ROUTES_URLS.ORDER_SELECT_PRODUCT, { id: order.id });
					navigate(url);
				},
				// @ts-ignore
				renderItem: (client) => client['Наименование'] || client['ФІО'],
			};
		}

		if (searchBy === 'product') {
			return {
				loadAsyncData: loadProductsAsOptions,
				onItemClick: async (product) => {
					// @ts-ignore
					const newOrder = prepareDTO((order) => order);

					// @ts-ignore
					const newUrlSearchParams = new URLSearchParams(searchParams);

					// @ts-ignore
					product.parentIds.forEach((parentId: string) => newUrlSearchParams.append('product', parentId));
					// @ts-ignore
					newUrlSearchParams.append('preselect', product.id);

					if (flow === 'order:empty-order') {
						const path = prepareUrl(ROUTES_URLS.ORDER_SELECT_PRODUCT, { id: newOrder.id as string });
						const url = `${path}?${newUrlSearchParams.toString()}`;

						return navigate(url);
					}

					const order = await asyncHandler(newOrder).unwrap();

					const path = prepareUrl(ROUTES_URLS.ORDER_SELECT_PRODUCT, { id: order.id });
					const url = `${path}?${newUrlSearchParams.toString()}`;

					navigate(url);
				},
				// @ts-ignore
				renderItem: (product) => product.title,
			};
		}

		return {
			loadAsyncData: undefined,
		};
	}, [searchBy]);

	return { config: mainSearchConfig, isLoading: state.isLoading };
};

function useGetMainSearchFlowAsyncHandlers(flow: UseMainSearchFlow) {
	const creationHandler = useCreateOrderMutation();
	const pathHandler = useUpdateOrderMutation();
	const { order } = useOrder();

	switch (flow) {
		case 'home:new-order':
			return {
				asyncHandler: creationHandler[0],
				state: creationHandler[1],
				prepareDTO: <TData>(creator: DtoCreatorFunction<TData>) => {
					const newOrder = prepareNewOrder();
					const data = creator(newOrder);

					return data;
				},
			};
		case 'order:empty-order':
			return {
				asyncHandler: pathHandler[0],
				state: pathHandler[1],
				prepareDTO: <TData>(creator: DtoCreatorFunction<TData>) => {
					// @ts-ignore
					const data = creator(order as Record<string, unknown>);

					return data;
				},
			};
		default:
			assertNever(flow);
	}
}

// !TEMP FOR DEMO ONLY - need to REMOVE
function prepareNewOrder() {
	const orderNumberPivot = Number(localStorage.getItem('prevOrderNumber') ?? 38383585);
	const orderNumber = orderNumberPivot + 1;

	localStorage.setItem('prevOrderNumber', String(orderNumber));

	const order = {
		number: orderNumber,
		createdAt: new Date().toString(),
		products: [],
		status: '',
		client: {
			meta: {
				manager: {
					id: '51bd0e99-4f57-11e6-80d1-3497f6001881',
					name: 'Андрущенко Анастасія',
				},
				supervisor: {
					id: '88eb5240-b5bc-11e4-9dd6-93ce85b7aa42',
					name: 'Кокриш Вікторія',
				},
				department: {
					id: '036ff180-bb60-11e4-9dd6-93ce85b7aa42',
					label: 'Софіївська Борщагівка',
					value: 'Софіївська Борщагівка',
				},
			},
		},
	};

	return order;
}
