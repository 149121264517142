import clsx from 'clsx';
import Button from 'components/Button';
import ColumnsSettingsMenu from 'components/ColumnsSettingsMenu';
import SettingButton from 'components/Table/Filters/SettingsButton';
import { breakPoints } from 'const';
import { useOrderContext } from 'contexts/OrderProvider';
import { useOrderViewer } from 'hooks/useOrderViewer';
import React from 'react';
import MediaQuery from 'react-responsive';
import DollarIcon from 'static/images/dollar.svg';
import FullScreen from 'static/images/expand.svg';
import Trash from 'static/images/trashOrders.svg';

import styles from './styles.module.css';

const ControlPanel: React.FC = () => {
	const { draftOrder, booleans, activeSuborder } = useOrderViewer();

	const orderCtx = useOrderContext();

	const selectedItems = booleans.isOnServicesTabInActiveSuborder ? orderCtx.selectedItems : activeSuborder.selectedProducts;

	const {
		toggleFullScreen,
		toggleIsAdjustmentOpen,
		closeAdjustment,
		toggleChangePriceMode,
		adjustColumns,
		productColumns,
		isAdjustmentOpen,
		isChangePriceMode,
	} = useOrderContext();

	const toggleAdjustmentModal = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.stopPropagation();

		toggleIsAdjustmentOpen();
	};

	const handleDeleteClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.stopPropagation();
		selectedItems.forEach((item) => draftOrder.removeService(item.id, 'suborder'));
	};

	const hasItemsToDelete = selectedItems?.length > 0;
	const isDeleteButtonDisabled = isChangePriceMode || !hasItemsToDelete;
	const isPriceModeButtonDisabled = !hasItemsToDelete;

	return (
		<>
			<MediaQuery minWidth={breakPoints.MOBILE}>
				<div className={styles.configButtonsWrapper}>
					<Button
						onClick={handleDeleteClick}
						icon={Trash}
						variant="bordered"
						className={clsx({ [styles.disabled]: isDeleteButtonDisabled })}
						disabled={isDeleteButtonDisabled}
					/>
					<Button
						icon={DollarIcon}
						variant="bordered"
						onClick={toggleChangePriceMode}
						className={clsx({ [styles.disabled]: isPriceModeButtonDisabled })}
						disabled={isPriceModeButtonDisabled}
					/>

					<SettingButton onClick={toggleAdjustmentModal}>
						<ColumnsSettingsMenu open={isAdjustmentOpen} onClose={closeAdjustment} columns={productColumns} setColumns={adjustColumns} />
					</SettingButton>

					<Button onClick={toggleFullScreen} icon={FullScreen} variant="bordered" />
				</div>
			</MediaQuery>
		</>
	);
};

export default ControlPanel;
