/* eslint-disable react/prop-types */
import clsx from 'clsx';
import BreadCrumbs from 'components/BreadCrumbs';
import { Breadcrumb } from 'components/BreadCrumbs/types';
import Button from 'components/Button';
import Checkbox from 'components/ColorfulSelect/Checkbox';
import CreatePriceTopBar from 'components/CreatePriceTopBar';
import { loadTypeOfPrices } from 'components/FormComponents/optionsLoaders';
import Input from 'components/Input';
import EmptyFilterProductState from 'components/OrderCreatePageComponents/EmptyFilterProductState';
import { useProductConfigurationToolModal } from 'components/OrderCreatePageComponents/ProductConfigurationToolModal/useProductConfigurationToolModal';
import ProductFilter from 'components/OrderCreatePageComponents/ProductFilter';
import PriceTypes from 'components/OrderCreatePageComponents/ProductSelectTopBar/PriceTypes';
import { PriceTypeItem } from 'components/OrderCreatePageComponents/ProductSelectTopBar/PriceTypes/types';
import TableActionsPanel from 'components/OrderCreatePageComponents/TableActionsPanel';
import RadioButtonSelect from 'components/RadioButtonSelect';
import { SelectOption } from 'components/Select/types';
import Spacer from 'components/Spacer';
import Spinner from 'components/Spinner';
import Table from 'components/Table';
import SelectProductMobileTable from 'components/Table/MobileViews/SelectProductMobileTable';
import { ActionCellContent, HeaderCellContent, RegularCellContent } from 'components/Table/TableComponents/ContentOrientedCells';
import MobileHeader from 'components/Table/TableComponents/MobileHeader';
import { HeaderCell, RegularCell } from 'components/Table/TableComponents/SpacingOrientedCells';
import Toggle from 'components/Toggle';
import { breakPoints, ROUTES_URLS } from 'const';
import { useLongestStringWidth } from 'hooks/orders';
import { useAdjustableColumns } from 'hooks/useAdustableColumns';
import { useInsertColumns } from 'hooks/useInsertColumns';
import { useSyncProductSelectionTracker } from 'hooks/useSyncProductsSelectionTracker';
import { Products } from 'models/IOrder';
import { adjustableColumnsIcons } from 'pages/CreateOrder/icons';
import { clientsIcons } from 'pages/Prices/icons';
import React, { MouseEvent, Suspense, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import MediaQuery, { useMediaQuery } from 'react-responsive';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Arrow from 'static/images/arrow-block-left.svg';
import { ReactComponent as Arrows } from 'static/images/currency-dollar.svg';
import Eye from 'static/images/eyeWhite.svg';
import { ReactComponent as Info } from 'static/images/info-circle.svg';
import Plus from 'static/images/plus.svg';
import { Product } from 'store/reducers/orders/types';
import { selectAllProducts } from 'store/reducers/products/selectors';
import { createPriceTypeColumn } from 'utils/columns';
import { formatNumberWithSpaces, numberify, prepareUrl, toPrecise } from 'utils/shared';
import { isString } from 'utils/type-guards';

import styles from './styles.module.css';
import { IProps } from './types';

const CreatePrice: React.FC<IProps> = ({ breadcrumbs, searchOnClick }) => {
	const [name, setName] = useState('');
	const [pricesTypeFilter, setPricesTypeFilter] = useState<SelectOption>();
	const [searchParams, setSearchParams] = useSearchParams();
	const [tableMode, setTableMode] = useState(false);
	const [checkedPhoto, setChechekedPhoto] = useState(false);

	const isMobile = useMediaQuery({ maxWidth: breakPoints.MOBILE - 1 });
	const isTablet = useMediaQuery({ query: `(min-width: ${breakPoints.MOBILE}px) and (max-width: ${breakPoints.DESKTOP - 1}px)` });
	const isSmallTablet = useMediaQuery({ query: `(min-width: ${breakPoints.MOBILE}px) and (max-width: 1099px)` });
	const isDesktop = useMediaQuery({ query: `(min-width: ${breakPoints.DESKTOP}px)` });
	const { openModal } = useProductConfigurationToolModal({ mode: 'create' });
	const products = useSelector(selectAllProducts(searchParams.toString()));
	const navigate = useNavigate();

	const longestTitle = useLongestStringWidth(products, (product) => product?.title, { limit: isSmallTablet ? 26 : isTablet ? 55 : 72 });

	const longestTitleWidth = isMobile ? 'auto' : `${longestTitle}ch`;
	const pinColumns = useMemo(() => (isTablet ? ['selection', 'brand'] : ['selection', 'title']), [isTablet]);

	const cellWidth = isSmallTablet ? '80px' : '120px';

	const handleClickCreate = () => {
		navigate(prepareUrl(ROUTES_URLS.SAVE_PRICE));
	};

	const initialColumns = useMemo(
		() => [
			...(isTablet
				? [
						{
							Header: ({ isAdjustable }) => {
								const title = 'Товар';

								if (isAdjustable) {
									return <>{title}</>;
								}

								return (
									<HeaderCell>
										<HeaderCellContent textAlign="left" value={title} />
									</HeaderCell>
								);
							},
							accessor: 'brand',
							width: longestTitleWidth,
							Cell: ({ row }) => (
								<RegularCell width={longestTitleWidth} onClick={() => pickProduct(row.original)}>
									<RegularCellContent className="tablet-cell" textAlign="left">
										<span>{row.original.brand}</span>
										<span>{row.original.title}</span>
									</RegularCellContent>
								</RegularCell>
							),
							icon: <adjustableColumnsIcons.Product />,
							hidden: false,
						},
				  ]
				: [
						{
							Header: ({ column, rows, isAdjustable }) => {
								const title = 'Товар';

								if (isAdjustable) {
									return <>{title}</>;
								}

								return (
									<HeaderCell {...(isDesktop && { justify: 'start' })}>
										<HeaderCellContent
											forceSortableMobile
											withIndeterminate
											sortable
											justify="center"
											column={column}
											disabled={rows.length < 2}
											value={title}
											{...(isDesktop && { justify: 'flex-start' })}
										/>
									</HeaderCell>
								);
							},
							accessor: 'title',
							Cell: ({ row }) => {
								const handleClick = () => {
									if (isMobile) return;

									openModal(row.original);
								};

								return (
									<RegularCell {...(isDesktop && { justify: 'start' })} width={longestTitleWidth} onClick={handleClick}>
										<RegularCellContent {...(isDesktop && { justify: 'flex-start' })} textAlign="left" value={row.values.title} />
									</RegularCell>
								);
							},
							width: cellWidth,
							icon: <adjustableColumnsIcons.Product />,
							hidden: false,
						},
						{
							Header: ({ column, rows, isAdjustable }) => {
								const title = 'Бренд';

								if (isAdjustable) {
									return <>{title}</>;
								}

								return (
									<HeaderCell {...(isDesktop && { justify: 'start' })}>
										<HeaderCellContent
											forceSortableMobile
											withIndeterminate
											sortable
											justify="center"
											column={column}
											disabled={rows.length < 2}
											value={title}
											{...(isDesktop && { justify: 'flex-start' })}
										/>
									</HeaderCell>
								);
							},
							accessor: 'brand',
							isAdjustable: true,
							Cell: ({ row }) => (
								<RegularCell {...(isDesktop && { justify: 'start' })}>
									<RegularCellContent textAlign="left" className={clsx(styles.highlightGray, styles.mediumBold)}>
										{row.values.brand}
									</RegularCellContent>
								</RegularCell>
							),
							width: cellWidth,
							icon: <adjustableColumnsIcons.Brand />,
							hidden: false,
						},
				  ]),
			{
				Header: ({ isAdjustable }) => {
					const title = !isMobile ? 'К-ть' : 'Кількість';

					if (isAdjustable) {
						return <>{title}</>;
					}

					return (
						<HeaderCell>
							<HeaderCellContent value={title} />
						</HeaderCell>
					);
				},
				accessor: 'quantity',
				isAdjustable: true,
				Cell: ({ row }) => {
					const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
						event.stopPropagation();

						openModal(row.original);
					};

					return (
						<RegularCell>
							{isMobile ? (
								<ActionCellContent onChangeClick={handleClick}>
									<span className={clsx(styles.highlightGray, styles.mediumBold)}>{row.values.quantity || 0}</span>
									<span>{row.original.measureUnit?.label}</span>
								</ActionCellContent>
							) : (
								<RegularCellContent width="100%" justify="center">
									{row.values.quantity || 0}
								</RegularCellContent>
							)}
						</RegularCell>
					);
				},
				width: cellWidth,
				icon: <adjustableColumnsIcons.Quantity />,
				hidden: false,
			},
			{
				Header: ({ column, rows, isAdjustable }) => {
					const title = 'Ціна';

					if (isAdjustable) {
						return <>{title}</>;
					}

					return (
						<HeaderCell>
							<HeaderCellContent
								sortable
								withIndeterminate
								column={column}
								disabled={rows.length < 2}
								value={title}
								className={clsx(styles.highlightPrimary600, styles.semibold)}
							/>
						</HeaderCell>
					);
				},
				accessor: 'price',
				isAdjustable: true,
				Cell: ({ row }) => {
					const price = numberify(row.values.price);
					const displayPrice = formatNumberWithSpaces(toPrecise(price));

					const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
						event.stopPropagation();

						alert('to price change page');
					};

					return (
						<RegularCell>
							{isMobile ? (
								<ActionCellContent onChangeClick={handleClick} className={clsx(styles.highlightPrimary600, styles.mediumBold)}>
									<span>{displayPrice}</span>
								</ActionCellContent>
							) : (
								<RegularCellContent width="100%" justify="center">
									{displayPrice}
								</RegularCellContent>
							)}
						</RegularCell>
					);
				},
				width: cellWidth,
				icon: <adjustableColumnsIcons.Price />,
				hidden: false,
			},
			{
				Header: ({ column, rows, isAdjustable }) => {
					const title = !isMobile ? 'Ост. ц.' : 'Ост. ціна';

					if (isAdjustable) {
						return <>{title}</>;
					}

					return (
						<HeaderCell>
							<HeaderCellContent
								sortable
								withIndeterminate
								column={column}
								disabled={rows.length < 2}
								value={title}
								className={clsx(styles.highlightPink)}
							/>
						</HeaderCell>
					);
				},
				accessor: 'finalPrice',
				isAdjustable: true,
				Cell: ({ row }) => {
					const price = numberify(row.values.finalPrice || 0);
					const formattedPrice = formatNumberWithSpaces(toPrecise(price));

					return (
						<RegularCell>
							<RegularCellContent
								width={isMobile ? 'auto' : '100%'}
								justify={isMobile ? 'flex-start' : 'center'}
								value={formattedPrice}
								className={clsx(styles.highlightPink, styles.mediumBold)}
							/>
						</RegularCell>
					);
				},
				sortable: true,
				width: cellWidth,
				icon: <adjustableColumnsIcons.FinalCost />,
				hidden: false,
			},
			{
				Header: ({ column, rows, isAdjustable }) => {
					const title = 'Резерв';

					if (isAdjustable) {
						return <>{title}</>;
					}

					return (
						<HeaderCell>
							<HeaderCellContent sortable withIndeterminate column={column} disabled={rows.length < 2} value={title} />
						</HeaderCell>
					);
				},
				accessor: 'inReserve',
				isAdjustable: true,
				Cell: ({ row }) => {
					return (
						<RegularCell>
							<RegularCellContent
								value={row.values.inReserve || '—'}
								width={isMobile ? 'auto' : '100%'}
								justify={isMobile ? 'flex-start' : 'center'}
								className={clsx(styles.highlightGray, styles.mediumBold)}
							/>
						</RegularCell>
					);
				},
				width: cellWidth,
				icon: <adjustableColumnsIcons.InReserve />,
				hidden: false,
			},
			{
				Header: ({ column, rows, isAdjustable }) => {
					const title = 'Залишок';

					if (isAdjustable) {
						return <>{title}</>;
					}

					return (
						<HeaderCell>
							<HeaderCellContent
								sortable
								withIndeterminate
								column={column}
								disabled={rows.length < 2}
								value="Залишок"
								className={clsx(styles.highlightPink, styles.mediumBold)}
							/>
						</HeaderCell>
					);
				},
				accessor: 'inStock',
				isAdjustable: true,
				Cell: ({ row }) => {
					return (
						<RegularCell>
							<RegularCellContent
								width={isMobile ? 'auto' : '100%'}
								justify={isMobile ? 'flex-start' : 'center'}
								value={row.original.quantity}
								textAlign="center"
								className={clsx(styles.highlightPink, styles.mediumBold)}
							/>
						</RegularCell>
					);
				},
				width: cellWidth,
				icon: <adjustableColumnsIcons.Remaining />,
				hidden: false,
			},
			{
				Header: ({ isAdjustable }) => {
					const title = 'Од-ці';

					if (isAdjustable) {
						return <>{title}</>;
					}

					return (
						<HeaderCell>
							<HeaderCellContent value={title} />
						</HeaderCell>
					);
				},
				accessor: 'measureUnit',
				isAdjustable: true,
				Cell: ({ row }) => (
					<RegularCell>
						<RegularCellContent width={isMobile ? 'auto' : '100%'} justify={isMobile ? 'flex-start' : 'center'}>
							{row.values.measureUnit?.label}.
						</RegularCellContent>
					</RegularCell>
				),
				skip: true,
				width: cellWidth,
				icon: <adjustableColumnsIcons.MeasureUnit />,
				hidden: false,
			},
			{
				Header: ({ isAdjustable }) => {
					const title = 'МІН';

					if (isAdjustable) {
						return <>{title}</>;
					}

					return (
						<HeaderCell>
							<HeaderCellContent value={title} />
						</HeaderCell>
					);
				},
				accessor: 'min',
				isAdjustable: true,
				Cell: () => (
					<RegularCell>
						<RegularCellContent width={isMobile ? 'auto' : '100%'} justify={isMobile ? 'flex-start' : 'center'}></RegularCellContent>
					</RegularCell>
				),
				width: cellWidth,
				icon: <adjustableColumnsIcons.Min />,
				hidden: false,
			},
			{
				Header: ({ isAdjustable }) => {
					const title = 'Вхід';

					if (isAdjustable) {
						return <>{title}</>;
					}

					return (
						<HeaderCell>
							<HeaderCellContent value={title} />
						</HeaderCell>
					);
				},
				accessor: 'entry',
				isAdjustable: true,
				Cell: () => (
					<RegularCell>
						<RegularCellContent width={isMobile ? 'auto' : '100%'} justify={isMobile ? 'flex-start' : 'center'}></RegularCellContent>
					</RegularCell>
				),
				width: cellWidth,
				icon: <adjustableColumnsIcons.Entry />,
				hidden: false,
			},
			{
				Header: ({ isAdjustable }) => {
					const title = 'ОПТ';

					if (isAdjustable) {
						return <>{title}</>;
					}

					return (
						<HeaderCell>
							<HeaderCellContent value={title} />
						</HeaderCell>
					);
				},
				accessor: 'bulk',
				isAdjustable: true,
				Cell: () => (
					<RegularCell>
						<RegularCellContent width={isMobile ? 'auto' : '100%'} justify={isMobile ? 'flex-start' : 'center'}></RegularCellContent>
					</RegularCell>
				),
				width: cellWidth,
				icon: <adjustableColumnsIcons.Group />,
				hidden: false,
			},
		],
		[isMobile, isTablet, longestTitleWidth, cellWidth, isDesktop],
	);

	const contentContainerRef = useRef<HTMLDivElement>(null);

	const { columns: adjustedColumns } = useAdjustableColumns(initialColumns, { saveConfigKey: '/Create-price' });

	const { columns, insertAfter, unInsert } = useInsertColumns({ columns: adjustedColumns, pivot: 'price', incrementOffset: true });

	const crumbs: Breadcrumb[] = breadcrumbs ?? [
		{ label: 'Прайси', href: prepareUrl(ROUTES_URLS.PRICES) },
		{ label: 'Прайс-лист №', href: '' },
	];

	const showSpinner = !products;
	// @ts-ignore
	const showEmptyState = !!products && products.length === 0;
	// @ts-ignore
	const showTable = !!products && products.length > 0;

	const handlePricesTypeSelect = (newOption: string | SelectOption) => {
		if (isString(newOption)) {
			setPricesTypeFilter({ label: newOption, value: newOption });
		} else {
			setPricesTypeFilter(newOption);
		}
	};

	const preselectedIds = Array.from(searchParams.entries())
		// @ts-ignore
		.filter(([key]) => key === 'preselect')
		// @ts-ignore
		.map(([value]) => ({ [value]: true } as const));

	const handleColumnsInsert = (_: number, item: PriceTypeItem, isActive: boolean) => {
		if (isActive) {
			const priceTypeColumn = createPriceTypeColumn({ header: item.label, cell: '—', accessor: item.label, color: item.fontColor });

			insertAfter(priceTypeColumn);
		} else {
			const position = columns.findIndex((col) => col.accessor === item.label);
			unInsert(position);
		}
	};

	const handleCheckPhoto = () => {
		setChechekedPhoto(!checkedPhoto);
	};

	const addProductToOrder = async (product: Product) => {
		// @ts-ignore
		await patch((prevOrder) => ({ ...prevOrder, products: [product, ...prevOrder.products] }));
		toast.success('Товар додано до заявки');
	};

	const pickProduct = (product: Products, e?: MouseEvent<HTMLElement>) => {
		e?.stopPropagation();
		openModal(product);
	};

	const handleToggleInputChange = () => {
		const newUrlSearchParams = new URLSearchParams(searchParams);

		if (newUrlSearchParams.has('inStock')) {
			newUrlSearchParams.delete('inStock');
		} else {
			newUrlSearchParams.append('inStock', 'true');
		}

		setSearchParams(newUrlSearchParams);
	};

	const handleShowTable = () => {
		setTableMode(true);
	};

	const handleHideTable = () => {
		setTableMode(false);
	};

	const handleNavigateToSavePrice = () => {
		navigate(prepareUrl(ROUTES_URLS.SAVE_PRICE));
	};

	return (
		<div className={clsx('main-wrapper', styles.mainWrapper)}>
			<div className={clsx('container', styles.container)}>
				<BreadCrumbs crumbs={crumbs} backLink={prepareUrl(ROUTES_URLS.PRICES)} />

				<MediaQuery minWidth={breakPoints.MOBILE}>
					<CreatePriceTopBar
						searchOnClick={searchOnClick}
						onChooseButtonClick={handleShowTable}
						tableMode={tableMode}
						handleBack={handleHideTable}
						nameOfMainBtn={'Повернутись до налаштування'}
					/>
				</MediaQuery>
				<Spacer height="31px" />
				{!tableMode && (
					<div className={styles.filtersBox}>
						<Input type={'text'} value={name} setValue={setName} placeholder="Додати назву нового прайсу" className={styles.input} />
						<RadioButtonSelect
							placeholder="Оберіть тип цін"
							loadAsyncOptions={loadTypeOfPrices}
							value={pricesTypeFilter}
							setValue={handlePricesTypeSelect}
							className={styles.typeOfPrice}
							leadingIcon={Arrows}
						/>
						<Checkbox label={'Фото'} checked={checkedPhoto} onChange={handleCheckPhoto} className={styles.checkbox} />
					</div>
				)}
				{tableMode ? (
					<div ref={contentContainerRef} className={clsx(styles.contentContainer, 'hide-scroll-bar')}>
						<div className={clsx(styles.productFilterContainer, 'hide-scroll-bar')}>
							<MediaQuery maxWidth={breakPoints.MOBILE - 1}>
								<PriceTypes onClick={handleColumnsInsert} className={clsx(styles.priceTypes, 'hide-scroll-bar')}>
									<Toggle hideIcon label="Тільки в наявності" onChange={handleToggleInputChange} />
								</PriceTypes>
							</MediaQuery>

							<ProductFilter />
						</div>

						<div className={clsx(styles.tableContainer)}>
							<MediaQuery minWidth={breakPoints.MOBILE}>
								<div className={styles.addBtnBox}>
									<Toggle hideIcon label="Тільки в наявності" onChange={handleToggleInputChange} />
									<Button
										text="Додати всю категорію"
										// onClick={handleChoosePriceButtonClick}
										icon={Plus}
										variant="default"
										background={'var(--primary-600)'}
									/>
								</div>
							</MediaQuery>

							{showSpinner && <Spinner />}

							{showEmptyState && <EmptyFilterProductState>За вказаними фільтрами товарів не знайдено</EmptyFilterProductState>}

							{showTable && (
								<>
									<MediaQuery maxWidth={breakPoints.MOBILE - 1}>
										<p className={clsx('text-md-semibold color-grey-900', styles.tableCaption)}>Товари:</p>
									</MediaQuery>

									<Table
										selectable
										columns={columns}
										pageType="order"
										pinColumns={pinColumns}
										className={styles.stickyHeader}
										preselectedRowIds={preselectedIds}
										useSyncSelectionTracker={useSyncProductSelectionTracker}
										mobileViewComponent={({ row }) => (
											<SelectProductMobileTable
												readonly
												row={row}
												onChangeQuantityClick={pickProduct}
												onAddProductClick={addProductToOrder}
											/>
										)}
										mobileHeader={({ headerGroups }) => (
											<Suspense fallback={<Spinner />}>
												<MobileHeader
													gridCustomLayout="44px auto"
													headerGroups={headerGroups}
													renderHeaders={['.', 'title']}
													justify="flex-start"
													className={styles.mobileHeader}
													bgColor="var(--gray-50)"
												/>
											</Suspense>
										)}
										// @ts-ignore
										allData={products}
										actionsPanelSlot={(props) => <TableActionsPanel className={styles.tableActionPanel} {...props} />}
									/>

									<MediaQuery maxWidth={breakPoints.MOBILE - 1}>
										<div className="safe-area-bottom" />
									</MediaQuery>
								</>
							)}
						</div>
					</div>
				) : (
					<div className={styles.infoBox}>
						<Info className={styles.infoIcon} />
						Оберіть налаштування, та оберіть номенклатуру, для створення прайс-листа
					</div>
				)}
				<MediaQuery maxWidth={breakPoints.MOBILE - 1}>
					<div className="safe-area-bottom" />
					<div className={styles.bottomPanel}>
						{!tableMode ? (
							<>
								<Button
									variant="default"
									text="Оберіть товар"
									icon={Eye}
									className={styles.filterBtn}
									background="#D71B87"
									onClick={handleShowTable}
								/>
								<Button
									variant="default"
									text="Коментар"
									icon={clientsIcons.Plus}
									className={styles.addClientBtn}
									background="#2E90FA"
								/>
							</>
						) : (
							<div className={styles.bottomButtons}>
								<Button
									variant="default"
									text="Повернутись до налаштування"
									icon={Arrow}
									className={styles.backBtn}
									background="#D71B87"
									onClick={handleHideTable}
								/>
								<Button
									variant="default"
									text="Додати всю категорію"
									icon={clientsIcons.Plus}
									className={styles.addClientBtn}
									background="#2E90FA"
									onClick={handleNavigateToSavePrice}
								/>
							</div>
						)}
					</div>
				</MediaQuery>
			</div>
			<Button text="Створити прайс" variant="rounded" background="var(--primary-600)" className={styles.btn} onClick={handleClickCreate} />
		</div>
	);
};

export default CreatePrice;
