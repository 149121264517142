import { ROUTES_URLS } from 'const';
import { useCreatingMode } from 'contexts/CreatingModeProvider';
import React, { MouseEvent } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { prepareUrl } from 'utils/shared';

import styles from './styles.module.css';
import { OrderExtraActionButtonProps } from './types';

const OrderExtraActionButton: React.FC<OrderExtraActionButtonProps> = ({ product }) => {
	const { id } = useParams();
	const { creatingMode } = useCreatingMode();

	function createCategorySearchParams(rowProduct) {
		const extractCategoryIds = (category) => {
			const ids = [];
			while (category) {
				ids.unshift(`category=${category.id}`);
				category = category.parent;
			}
			return ids.join('&');
		};

		return extractCategoryIds(rowProduct.category);
	}

	const searchParams = `?${createCategorySearchParams(product)}`;

	const url = creatingMode
		? prepareUrl(ROUTES_URLS.CREATE_ORDER_SELECT_PRODUCT) + searchParams
		: prepareUrl(ROUTES_URLS.ORDER_SELECT_PRODUCT, { id }) + searchParams;

	const handleClick = (event: MouseEvent<HTMLAnchorElement>) => {
		event.stopPropagation();
	};

	return (
		<NavLink data-order-extra-option-button to={url} onClick={handleClick} className={styles.button}>
			<span>...</span>
		</NavLink>
	);
};

export default OrderExtraActionButton;
