import { ROUTES_URLS } from 'const';
import React, { lazy, Suspense, useState } from 'react';

const ClientCreatePage = lazy(() => import('pages/ClientCreatePage'));
const ClientPage = lazy(() => import('pages/ClientPage'));
const Clients = lazy(() => import('pages/Clients'));
const SelectClient = lazy(() => import('pages/CreateOrder/SelectClient'));
const SelectProduct = lazy(() => import('pages/CreateOrder/SelectProduct'));
const Orders = lazy(() => import('pages/Orders'));
const ClientOrders = lazy(() => import('pages/ClientOrdersPage'));
const EmployeePreview = lazy(() => import('pages/Employee/EmployeePreview'));
const EmployeeCreate = lazy(() => import('pages/Employee/EmployeeCreate'));
const EmployeeEdit = lazy(() => import('pages/Employee/EmployeeEdit'));
const Employees = lazy(() => import('pages/Employees'));
const Documents = lazy(() => import('pages/Documents'));
const PaintToning = lazy(() => import('pages/PaintToning'));
const CommercialProposal = lazy(() => import('pages/CommercialProposal'));
const Prices = lazy(() => import('pages/Prices'));

const PDFViewer = lazy(() => import('components/PDFViewer'));
const Document = lazy(() => import('components/PDFViewer/components/Document'));
const Page = lazy(() => import('components/PDFViewer/components/Page'));
const Pagination = lazy(() => import('components/PDFViewer/components/Pagination'));
const Toolbar = lazy(() => import('components/PDFViewer/components/Toolbar'));
const DownloadTool = lazy(() => import('components/PDFViewer/components/Tools/DownloadTool'));
const FullscreenTool = lazy(() => import('components/PDFViewer/components/Tools/FullscreenTool'));
const PrinterTool = lazy(() => import('components/PDFViewer/components/Tools/PrinterTool'));
const ThumbnailsTool = lazy(() => import('components/PDFViewer/components/Tools/ThumbnailsTool'));
const ShareTool = lazy(() => import('components/PDFViewer/components/Tools/ShareTool'));
const Block = lazy(() => import('components/PDFViewer/ui/Block'));

import OrderViewerProvider from 'contexts/OrderViewerProvider';
import CreatePrice from 'pages/CrearePrice';
import SavePricePage from 'pages/CrearePrice/SavePricePage';
import Order from 'pages/Order';
import OrderProducts from 'pages/OrderProducts';
import Services from 'pages/OrderServices';
import SplitOrder from 'pages/SplitOrder';

import PageErrorBoundary from './components/ErrorBoundaries/PageErrorBoundary';
import { clientOrdersHandler, fetchClient, fetchClients } from './handlers/clients';
import { fetchEmployee, fetchEmployees } from './handlers/employees';
import { ordersLoader, selectClientForCreateOrder, selectProductsHandler, singleOrderHandler } from './handlers/orders';
import { paintToningLoader } from './handlers/paint-toning';

export const SharedLayoutRoutes = [
	{
		path: ROUTES_URLS.ORDERS,
		element: <Orders />,
		loader: ordersLoader,
	},
	{
		element: <SelectClient />,
		path: ROUTES_URLS.ORDER_CREATE,
		loader: selectClientForCreateOrder,
		children: [
			{
				element: <Order creatingMode={true} />,
				children: [
					{ index: true, element: <OrderProducts creatingMode={true} /> },
					{ path: ROUTES_URLS.ORDER_SERVICES, element: <Services /> },
				],
			},
			{
				children: [
					{
						path: `${ROUTES_URLS.ORDER_CREATE}/split`,
						element: <SplitOrder />,
					},
				],
			},
		],
	},
	{
		element: <Order creatingMode={true} />,
		path: ROUTES_URLS.NEW_ORDER,
		children: [
			{ index: true, element: <OrderProducts creatingMode={true} /> },
			{ path: ROUTES_URLS.ORDER_SERVICES, element: <Services /> },
		],
	},
	{
		path: ROUTES_URLS.ORDER_SELECT_PRODUCT,
		loader: selectProductsHandler,
		element: <SelectProduct />,
	},
	{
		path: ROUTES_URLS.CREATE_ORDER_SELECT_PRODUCT,
		loader: selectProductsHandler,
		element: <SelectProduct />,
	},
	{
		path: ROUTES_URLS.CLIENTS,
		loader: fetchClients,
		element: <Clients />,
	},
	{
		path: ROUTES_URLS.CLIENT_PAGE,
		errorElement: <PageErrorBoundary />,
		loader: fetchClient,
		element: <ClientPage />,
	},
	{
		path: ROUTES_URLS.CLIENT_CREATE,
		element: <ClientCreatePage />,
	},
	{
		path: ROUTES_URLS.CLIENT_ORDERS,
		loader: clientOrdersHandler,
		element: <ClientOrders />,
	},
	{
		path: ROUTES_URLS.EMPLOYEES,
		loader: fetchEmployees,
		element: <Employees />,
	},
	{
		path: ROUTES_URLS.EMPLOYEE,
		loader: fetchEmployee,
		element: <EmployeePreview />,
	},
	{
		path: ROUTES_URLS.EMPLOYEE_CREATE,
		element: <EmployeeCreate />,
	},
	{
		path: ROUTES_URLS.EMPLOYEE_EDIT,
		loader: fetchEmployee,
		element: <EmployeeEdit />,
	},
	{
		path: 'order',
		element: <OrderViewerProvider />,
		children: [
			{
				path: ':id',
				loader: singleOrderHandler,
				element: <Order />,
				children: [
					{ index: true, element: <OrderProducts /> },
					{ path: ROUTES_URLS.ORDER_SERVICES, element: <Services /> },
				],
			},
			{
				path: ':id/split',
				loader: singleOrderHandler,
				element: <SplitOrder />,
			},
		],
	},
	{
		path: ROUTES_URLS.DOCUMENTS,
		element: <Documents />,
		loader: fetchClient,
	},
	{
		path: ROUTES_URLS.PAINT_TONING,
		element: <PaintToning />,
		loader: paintToningLoader,
	},
	{
		path: ROUTES_URLS.PRICES,
		element: <Prices />,
	},
	{
		path: ROUTES_URLS.CREATE_PRICE,
		element: <CreatePrice />,
		loader: selectProductsHandler,
	},
	{
		path: ROUTES_URLS.SAVE_PRICE,
		element: <SavePricePage />,
	},
	{
		path: ROUTES_URLS.COMMERCIAL_PROPOSAL,
		element: <CommercialProposal />,
	},
	{
		path: 'pdf',
		element: <PDFTestPage />,
	},
];

// example of a PDF viewer usage
function PDFTestPage() {
	const [isOpened, setIsOpened] = useState(false);

	const fileUrl = 'GitNotesForProfessionals.pdf';
	const handleClose = () => {
		setIsOpened(false);
	};

	const toggleOpen = () => {
		setIsOpened(!isOpened);
	};

	return (
		<div>
			<button
				type="button"
				onClick={toggleOpen}
				style={{
					padding: '12px 16px',
					backgroundColor: 'steelblue',
					color: 'white',
					borderRadius: '6px',
					display: 'block',
					margin: '12px auto 0 auto',
				}}
			>
				Reveal pdf
			</button>

			<Suspense fallback={<div>Loading...</div>}>
				{!!isOpened && (
					<PDFViewer embedMode="aboveAll" onClose={handleClose} filePath={fileUrl}>
						<Toolbar>
							<ThumbnailsTool />

							<Block ml="auto">
								<PrinterTool />
								<DownloadTool />
								<FullscreenTool />
								<ShareTool />
							</Block>
						</Toolbar>

						<Document>
							<Page />
						</Document>

						<Pagination />
					</PDFViewer>
				)}
			</Suspense>
		</div>
	);
}
