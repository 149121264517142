import clsx from 'clsx';
import Button from 'components/Button';
import Modal from 'components/Modal';
import ModalHeader from 'components/Modal/ModalHeader';
import type { IProps as ModalProps } from 'components/Modal/types';
import ProductItem from 'components/OrderCreatePageComponents/BulkProductSelection/Item';
import TipCard from 'components/TipCard';
import { useOrderViewer } from 'hooks/useOrderViewer';
import React, { useState } from 'react';
import PlusIcon from 'static/images/plus.svg';
import { numberify } from 'utils/shared';

import styles from './styles.module.css';

type TransferProductsModalProps = Pick<ModalProps, 'onClose'> & {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	onProductClick(product: any): void;
	targetSuborderIndex: number;
};

const TransferProductsModal: React.FC<TransferProductsModalProps> = ({ onClose, onProductClick, targetSuborderIndex }) => {
	const {
		activeSuborder: { selectedProducts, index },
		suborders: { handlers },
	} = useOrderViewer();
	const [writableProducts, setWritableProducts] = useState(selectedProducts);

	const handleProductsTransfer = () => {
		handlers.transferProductsWithData({ from: index, to: targetSuborderIndex, data: writableProducts });
		onClose?.();
	};

	const handleItemQuantityChange = (id: string, newQuantity: number) => {
		setWritableProducts(
			writableProducts.map((item) => {
				if (String(item.id) !== String(id)) return item;

				return {
					...item,
					amount: newQuantity,
				};
			}),
		);
	};

	return (
		<Modal noAwayClick open stackable onClose={onClose} className={styles.modal} maxWidth="800px" maxHeight="518px">
			<ModalHeader title={<span className={styles.headerTitle}>Перенесення товарів</span>} onXCloseClick={onClose} className={styles.header} />

			<ul className={clsx(styles.list)}>
				{writableProducts.map((product) => (
					<ProductItem
						key={product?.id}
						onClick={onProductClick.bind(null, product)}
						onQuantityChange={handleItemQuantityChange.bind(null, String(product.id))}
						initialValue={numberify(product.amount)}
						price={product.price}
						brand={product.brand}
						title={product.title}
						unit={product.unit.title}
					/>
				))}
			</ul>

			<TipCard message="Інформацію про наявність можна переглянути в картці продукту" />

			<div className={styles.controls}>
				<Button variant="default" onClick={onClose} className={styles.cancel} text="Скасувати" />
				<Button
					variant="rounded"
					background="var(--success-500)"
					onClick={handleProductsTransfer}
					text={`Перенести в заявку ${targetSuborderIndex}`}
					icon={PlusIcon}
				/>
			</div>
		</Modal>
	);
};

export default TransferProductsModal;
