import clsx from 'clsx';
import AutocompleteInput from 'components/AutocompleteInput';
import type { IProps as AutocompleteInputProps } from 'components/AutocompleteInput/types';
import CharsCounter from 'components/CharsCounter';
import ColorfulSelect from 'components/ColorfulSelect';
import Checkbox from 'components/ColorfulSelect/Checkbox';
import type { IProps as CheckboxProps } from 'components/ColorfulSelect/Checkbox/types';
import type { IProps as ColorfulSelectProps } from 'components/ColorfulSelect/types';
import Input from 'components/Input';
import PhoneInput from 'components/PhoneInput';
import RichTextEditor from 'components/RichTextEditor';
import Select from 'components/Select';
import { MAX_CHARS } from 'const';
import { Segment } from 'models/IClient';
import React from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { ReactComponent as BackIcon } from 'static/images/corner-down-right.svg';
import { ReactComponent as MagnifyGlassIcon } from 'static/images/Magnify.svg';
import { isNullish, isObject } from 'utils/type-guards';

import CopyToClipboard from '../FieldActions/CopyToClipboard';
import LocateAddressOnMap from '../FieldActions/LocateAddressOnMap';
import FormField from '../FormField';
import formFieldStyles from '../FormField/styles.module.css';
import CustomToolbarComponent from './CustomToolbarComponent';
import type {
	AutocompleteFieldForClientProps,
	AutocompleteFieldWithOnSelectActionProps,
	BaseFieldProps,
	InputWithCopyToClipboardActionProps,
	PhoneInputProps,
	PlainInputProps,
	PlainSelectProps,
	SelectWithLocateAddressOnMapActionProps,
} from './types';

export const InputWithCopyToClipboardAction = ({
	name,
	label,
	type = 'text',
	afterCopyText = 'Скопійовано в буфер',
	beforeCopyText = 'Скопіювати',
	...restProps
}: InputWithCopyToClipboardActionProps) => {
	return (
		<FormField
			name={name}
			label={label}
			component={Input}
			type={type}
			actionSlot={
				<CopyToClipboard name={name} afterCopyText={afterCopyText} beforeCopyText={beforeCopyText} className={formFieldStyles.actionSlot} />
			}
			{...restProps}
		/>
	);
};

export const SelectWithLocateAddressOnMapAction = ({ name, label, ...restProps }: SelectWithLocateAddressOnMapActionProps) => {
	return (
		<FormField
			name={name}
			label={label}
			component={Select}
			valuesList={['1', '2', '3']}
			className={formFieldStyles.select}
			actionSlot={<LocateAddressOnMap className={formFieldStyles.actionSlot} />}
			{...restProps}
		/>
	);
};

export const PlainInput = ({ name, label, type = 'text', ...restProps }: PlainInputProps) => {
	return <FormField name={name} label={label} component={Input} type={type} {...restProps} />;
};

export const PlainSelect = ({ name, label, ...restProps }: PlainSelectProps) => {
	return <FormField name={name} label={label} component={Select} valuesList={['1', '2', '3']} className={formFieldStyles.select} {...restProps} />;
};
export const MultipleSelect = ({ name, label, ...restProps }: PlainSelectProps) => {
	return (
		<FormField
			name={name}
			label={label}
			component={({ onChange, value, ...props }) => {
				return <Select {...props} multiple setValue={(v) => onChange(v)} value={value} />;
			}}
			className={formFieldStyles.select}
			{...restProps}
		/>
	);
};

export const EmbeddedSelect = ({ name, label, ...restProps }: PlainSelectProps) => {
	return (
		<FormField
			name={name}
			label={label}
			component={Select}
			valuesList={['Жовтень', 'Січень', 'Лютий']}
			className={clsx(formFieldStyles.select, formFieldStyles.embeddedSelect)}
			{...restProps}
		/>
	);
};

export const RichText = ({ name, label, hideToolbar, ...restProps }: PlainSelectProps & { hideToolbar?: boolean }) => {
	return (
		<FormField
			name={name}
			renderLabel={(classNames: string) => <span className={classNames}>{label}</span>}
			fieldClassName={formFieldStyles.richTextField}
			component={({ value, ...field }) => {
				const { plainText = '' } = value ?? {};

				const charsWithoutSpaces = plainText?.replace(/\s/g, '');
				const actualCharsCount = charsWithoutSpaces?.length ?? 0;
				return (
					<>
						<RichTextEditor {...field} hideToolbar={hideToolbar} value={value?.html ?? ''} maxChars={MAX_CHARS} />
						<CharsCounter limit={MAX_CHARS} count={actualCharsCount} className={formFieldStyles.charsCounter} />
					</>
				);
			}}
			{...restProps}
		/>
	);
};

export const RichTextWithSelect = ({ name, label, ...restProps }: PlainSelectProps) => {
	const { watch } = useFormContext();
	const richEditor = watch(name);
	const { plainText = '' } = richEditor ?? {};

	const charsWithoutSpaces = plainText.replace(/\s/g, '');
	const actualCharsCount = charsWithoutSpaces?.length ?? 0;

	return (
		<FormField
			name={name}
			label={label}
			fieldClassName={clsx(formFieldStyles.richTextField, formFieldStyles.withSelect)}
			component={({ value, ...field }) => (
				<RichTextEditor
					{...field}
					value={value?.html ?? ''}
					maxChars={MAX_CHARS}
					toolbarConfig={(config) => {
						return {
							...config,
							options: ['colorPicker', ...config.options],
							colorPicker: {
								component: CustomToolbarComponent,
							},
						};
					}}
				/>
			)}
			{...restProps}
		>
			<CharsCounter limit={MAX_CHARS} count={actualCharsCount} className={formFieldStyles.charsCounter} />
		</FormField>
	);
};

export const PhoneInputWithCopyToClipboardAction = ({
	name,
	label,
	type = 'number',
	afterCopyText = 'Скопійовано в буфер',
	beforeCopyText = 'Скопіювати',
	mask,
	...restProps
}: PhoneInputProps) => {
	return (
		<FormField
			name={name}
			label={label}
			component={PhoneInput}
			type={type}
			mask={mask}
			actionSlot={
				<CopyToClipboard name={name} afterCopyText={afterCopyText} beforeCopyText={beforeCopyText} className={formFieldStyles.actionSlot} />
			}
			{...restProps}
		/>
	);
};

export const AutocompleteInputWithSearchIcon = ({ name, label, ...restProps }: BaseFieldProps) => {
	return (
		<FormField
			{...restProps}
			name={name}
			label={label}
			component={AutocompleteInput}
			leadingIcon={MagnifyGlassIcon}
			valuesList={[
				{ label: 'Київ', value: '1' },
				{ label: 'Київ 2', value: '2' },
				{ label: 'Київ 3', value: '2' },
			]}
		/>
	);
};

export const AutocompleteField = ({ name, label, valuesList, ...restProps }: AutocompleteInputProps & BaseFieldProps) => {
	return <FormField {...restProps} name={name} label={label} component={AutocompleteInput} valuesList={valuesList} />;
};

type ColorfulSelectFieldProps = Pick<BaseFieldProps, 'name'> &
	Omit<ColorfulSelectProps<Record<string, unknown>>, 'onSelect' | 'value'> & {
		defaultValue?: Segment;
	};

export const ColorfulSelectField = ({ name, options, defaultValue, ...restProps }: ColorfulSelectFieldProps) => {
	const { field } = useController({ name, defaultValue });
	const { onChange, value } = field;

	return (
		<ColorfulSelect
			value={defaultValue || value}
			options={options}
			onSelect={onChange}
			getOptionLabel={(option) => option.title}
			getOptionValue={(option) => option.id}
			{...restProps}
		/>
	);
};

type CheckboxFieldProps = { name: string } & CheckboxProps;

export const CheckboxField = ({ name, label, ...restProps }: Omit<CheckboxFieldProps, 'checked' | 'ref'>) => {
	const { field } = useController({ name });
	const { onChange, value } = field;

	return <Checkbox checked={!!value} onChange={onChange} label={label} {...restProps} />;
};
export const AutocompleteFieldWithOnSelectAction = ({ onOptionSelect, ...props }: Omit<AutocompleteFieldWithOnSelectActionProps, 'ref'>) => {
	return (
		<FormField
			{...props}
			shouldUnregister={false}
			component={({ onChange, ...fieldProps }) => {
				const onSelect = (value: unknown) => {
					onChange(value);

					if (value !== null) {
						onOptionSelect?.(value);
					}
				};

				return <AutocompleteInput {...fieldProps} setValue={onSelect} />;
			}}
		/>
	);
};
export const AutocompleteFieldForClient = ({ onOptionSelect, onClick, ...props }: Omit<AutocompleteFieldForClientProps, 'ref'>) => {
	return (
		<FormField
			{...props}
			shouldUnregister={false}
			component={({ onChange, ...fieldProps }) => {
				const onSelect = (value: unknown) => {
					onChange(value);

					if (value !== null) {
						onOptionSelect?.(value);
					}
				};

				return (
					<AutocompleteInput
						{...fieldProps}
						renderDropdownHeader={(suggestion) => {
							if (!suggestion) {
								return null;
							}
							if (isObject(suggestion) && isNullish(suggestion.value)) {
								return null;
							}

							const handleClientPreviewClick = (e: React.MouseEvent<HTMLButtonElement>) => {
								e.stopPropagation();
								onClick(suggestion);
							};

							return (
								<li className={formFieldStyles.clientDropdownHeaderWrapper}>
									<button type="button" onClick={handleClientPreviewClick} className={formFieldStyles.clientDropdownHeader}>
										<span>Переглянути клієнта</span>
										<BackIcon />
									</button>
								</li>
							);
						}}
						setValue={onSelect}
					/>
				);
			}}
		/>
	);
};
