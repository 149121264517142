import { LoaderFunction } from 'react-router-dom';
import { store } from 'store';
import { clientsSliceApi } from 'store/reducers/clients/clientsSliceApi';
import { ordersSliceApi } from 'store/reducers/orders/ordersSliceApi';
import { usersSliceApi } from 'store/reducers/users/usersSliceApi';

import { throwNoIdSegmentWasProvidedError } from './errors';

export const fetchClients: LoaderFunction = async ({ request }) => {
	const url = new URL(request.url);
	const searchParams = url.searchParams;

	const promise = Promise.all([store.dispatch(clientsSliceApi.endpoints.getClients.initiate(searchParams.toString())).unwrap()]);

	return promise;
};
export const fetchClient: LoaderFunction = async ({ params }) => {
	const { id } = params;

	if (!id) {
		throwNoIdSegmentWasProvidedError();
	}

	const promise = Promise.all([
		store.dispatch(clientsSliceApi.endpoints.getClientById.initiate(id)).unwrap(),
		store.dispatch(usersSliceApi.endpoints.getManagers.initiate(undefined)).unwrap(),
		store.dispatch(usersSliceApi.endpoints.getWarehouses.initiate(undefined)).unwrap(),
		store.dispatch(usersSliceApi.endpoints.getOrganizations.initiate(undefined)).unwrap(),
	]);

	return promise;
};

export const clientOrdersHandler: LoaderFunction = async (route) => {
	const { request, params } = route;
	const url = new URL(request.url);
	const searchParams = url.searchParams;
	const id = params.id;

	const promise = Promise.all([
		store.dispatch(ordersSliceApi.endpoints.getClientsOrders.initiate({ id, queryParams: searchParams.toString() })).unwrap(),
		store.dispatch(clientsSliceApi.endpoints.getClients.initiate('')).unwrap(),
		store.dispatch(usersSliceApi.endpoints.getManagers.initiate(undefined)).unwrap(),
		store.dispatch(usersSliceApi.endpoints.getWarehouses.initiate(undefined)).unwrap(),
		store.dispatch(usersSliceApi.endpoints.getOrganizations.initiate(undefined)).unwrap(),
	]);

	return promise;
};
