import { useOrderColumns } from 'columns/order';
import { PriceTypeItem } from 'components/OrderCreatePageComponents/ProductSelectTopBar/PriceTypes/types';
import { useCreatingMode } from 'contexts/CreatingModeProvider';
import type { OrderContext } from 'contexts/OrderProvider';
import { useInsertColumns } from 'hooks/useInsertColumns';
import { useOrderCart } from 'hooks/useOrderCart';
import { useOrderViewer } from 'hooks/useOrderViewer';
import { useCallback, useMemo, useState } from 'react';
import { createPriceTypeColumn } from 'utils/columns';

export const useOrderContextValue = () => {
	const [isFullScreenMode, setIsFullScreenMode] = useState(false);
	const [isAdjustmentOpen, setIsAdjustmentOpen] = useState(false);
	const [selectedItems, setSelectedItems] = useState([]);
	const [isChangePriceMode, setIsChangePriceMode] = useState<boolean>(false);
	const { draftOrder } = useOrderViewer();
	const { creatingMode } = useCreatingMode();
	const cart = useOrderCart();

	const {
		columns: adjustedColumns,
		pinColumns,
		adjust,
	} = useOrderColumns({
		// @ts-ignore
		onInputChange: creatingMode ? cart.uptateAmountOfProducts : draftOrder.onInputChangeInProducts,
		adjustSaveKey: '/order',
	});

	const { columns, insertAfter, unInsert } = useInsertColumns({ columns: adjustedColumns, pivot: 'price', incrementOffset: true });

	const togglePriceColumns = useCallback(
		(_: number, item: PriceTypeItem, isActive: boolean) => {
			if (isActive) {
				const priceTypeColumn = createPriceTypeColumn({
					header: item.label,
					cell: '-',
					accessor: item.label,
					color: item.fontColor,
				});

				insertAfter(priceTypeColumn);
			} else {
				const position = columns.findIndex((col) => col.accessor === item.label);
				unInsert(position);
			}
		},
		[columns],
	);

	const ctx: OrderContext = useMemo(() => {
		return {
			toggleFullScreen: () => setIsFullScreenMode(!isFullScreenMode),
			toggleIsAdjustmentOpen: () => setIsAdjustmentOpen(!isAdjustmentOpen),
			closeAdjustment: () => setIsAdjustmentOpen(false),
			isFullScreenMode,
			isAdjustmentOpen,
			selectedItems,
			adjustColumns: adjust,
			pinColumns,
			togglePriceColumns,
			productColumns: adjustedColumns,
			toggleSelectedItems: (items) => setSelectedItems(items.map(({ original }) => original)),
			isChangePriceMode,
			toggleChangePriceMode: () => setIsChangePriceMode(!isChangePriceMode),
			exitChangePriceMode: () => setIsChangePriceMode(false),
		};
	}, [isFullScreenMode, isAdjustmentOpen, selectedItems, adjust, pinColumns, adjustedColumns, isChangePriceMode]);

	return ctx;
};
