import { useOrderCart } from 'hooks/useOrderCart';
import { Products } from 'models/IOrder';
import { ICreateOrderSliceState } from 'store/reducers/orders/types';

export const useProductConfigurationToolModal = ({ mode }: { mode: ICreateOrderSliceState['mode'] }) => {
	const cart = useOrderCart();

	const closeModal = () => {
		cart.closeModal();
		cart.clearMaybeSelectOne();
	};

	const openModal = (product: Products) => {
		cart.maybeSelectOne({ product, mode });
		cart.openModal();
	};

	return { closeModal, openModal, isOpenModal: cart.isModalOpened };
};
