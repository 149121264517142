import { useGetServicesQuery } from 'store/reducers/orders/ordersSliceApi';

function findParentObjectById(data, targetId) {
	function searchInServices(services) {
		for (const service of services) {
			if (service.id === targetId) {
				return service;
			}
			if (service.services) {
				const found = searchInServices(service.services);
				if (found) return found;
			}
			if (service.subCategories) {
				const found = searchInServices(service.subCategories);
				if (found) return found;
			}
		}
		return null;
	}

	for (const key in data) {
		if (data.hasOwnProperty(key)) {
			if (data[key].id === targetId) {
				return data[key];
			}
			if (data[key].services) {
				const found = searchInServices(data[key].services);
				if (found) return data[key];
			}
			if (data[key].subCategories) {
				const found = searchInServices(data[key].subCategories);
				if (found) return data[key];
			}
		}
	}
	return null;
}

export const useTransformServices = (draftOrderServices) => {
	const { data: servicesList } = useGetServicesQuery(undefined);

	// Ensure draftOrderServices is defined and not null
	if (!draftOrderServices) {
		return [];
	}

	// Helper function to find service by ID in the servicesList
	const findServiceById = (id) => findParentObjectById(servicesList, id);

	// Helper function to transform a service
	const transformService = (service, draftService) => ({
		id: service?.id,
		serviceId: service?.code || draftService?.code,
		service: service?.title,
		price: draftService.price || 0,
		quantity: draftService.amount ? Math.floor(draftService.amount) : 0,
		type: service?.subCategories?.find((item) => item.id === draftService?.category?.id)?.services,
		serviceType: 'regular',
		workType: { title: draftService.title, id: draftService.id },
		isSaved: true,
		transformed: true,
		firstType: {
			id: draftService?.category?.id,
			title: draftService?.category?.title,
			services: service?.subCategories?.find((item) => item?.id === draftService?.category?.id)?.services,
		},
	});

	let transformedServices = [];

	// Check if draftOrderServices is an array
	if (Array.isArray(draftOrderServices)) {
		transformedServices = draftOrderServices.map((draftService) => {
			const service = findServiceById(draftService.id);
			if (draftService.transformed) {
				return draftService;
			}
			return transformService(service, draftService);
		});
	} else if (typeof draftOrderServices === 'object' && draftOrderServices !== null) {
		transformedServices = Object.keys(draftOrderServices)
			.filter((key) => draftOrderServices[key].id)
			.map((key) => {
				const draftService = draftOrderServices[key];
				const service = findServiceById(draftService.id);
				if (draftService.transformed) {
					return draftService;
				}
				return transformService(service, draftService);
			});
	}

	// Return the transformed services if servicesList is defined
	return servicesList ? transformedServices : [];
};
