import ColorfulSelect from 'components/ColorfulSelect';
import { breakPoints } from 'const';
import { useCreatingMode } from 'contexts/CreatingModeProvider';
import { useOrderCart } from 'hooks/useOrderCart';
import { useOrderViewer } from 'hooks/useOrderViewer';
import React from 'react';
import MediaQuery from 'react-responsive';

import RootButton from './RootButton';
import styles from './styles.module.css';
import type { ServiceOptionSelectDependantFieldProps } from './types';
import { useBackdrop } from './useBackdrop';

const ServiceOptionSelectDependantField = ({ id, placeholder }: ServiceOptionSelectDependantFieldProps) => {
	const { activeSuborder, draftOrder, utils } = useOrderViewer();
	const { creatingMode } = useCreatingMode();
	const cart = useOrderCart();
	const isOnSplitPage = utils.isOnSuborderSplittingPage();
	const source = isOnSplitPage ? activeSuborder : draftOrder;

	const { ref } = useBackdrop();

	const handler = creatingMode ? cart.setServiceWorkTypeInNewOrder : source.onSelectChangeServiceWorkType;
	const service = () => {
		if (!isOnSplitPage) {
			if (creatingMode) {
				return cart.createOrderDataState.services.find((item) => item.id === id);
			} else {
				if (Array.isArray(draftOrder?.original?.services)) {
					return draftOrder?.original?.services?.find((item) => item.id === id);
				} else {
					return undefined;
				}
			}
		} else {
			if (typeof activeSuborder?.services === 'object') {
				const arr = Object.values(activeSuborder?.services);
				return arr.find((item) => item.id === id);
			} else {
				// @ts-ignore
				return activeSuborder?.services?.find((item) => item.id === id);
			}
		}
	};

	const getOptionsList = () => {
		return service()?.firstType?.services || service()?.type?.[0]?.services || undefined;
	};

	const getValueForSelect = () => {
		return service()?.workType || getOptionsList()?.[0] || '';
	};

	return (
		<>
			<MediaQuery minWidth={breakPoints.MOBILE}>
				<ColorfulSelect
					closeOnSelect
					popupHeaderTitle="Виберіть за параметрами"
					// @ts-ignore
					onSelect={(v) => handler(id, v)}
					// @ts-ignore
					popupHeaderClassName={styles.popupHeader}
					// @ts-ignore
					value={getValueForSelect()}
					placeholder={placeholder}
					// @ts-ignore
					rootButton={(props) => <RootButton {...props} disabled={!value} title={placeholder} />}
					// @ts-ignore
					options={getOptionsList() || []}
				/>
			</MediaQuery>
			{/* <MediaQuery maxWidth={breakPoints.MOBILE - 1}>
				<Select
					// @ts-ignore
					disabled={!majorOption?.id && !options?.length}
					// @ts-ignore
					wrapperClassName={styles.selectField}
					// @ts-ignore
					value={value}
					// @ts-ignore
					setValue={(v) => handler(id, v)}
					onClick={toggleVisibility}
					onClose={hide}
					placeholder={placeholder}
					valuesList={test ?? []}
					dropdownHeader="Виберіть за параметрами"
				/>
			</MediaQuery> */}

			<div ref={ref} className={styles.backdrop} />
		</>
	);
};

export default ServiceOptionSelectDependantField;
