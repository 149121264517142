/* eslint-disable @typescript-eslint/no-explicit-any */
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import axios from 'axios';
import localForage from 'localforage';
import { IClientData } from 'models/IClient';
import { IClientsFilters } from 'models/IClientsFilters';
import { filterOrdersByClientAndFilters } from 'pages/CreateOrder/mockDataServer';
import { API_URL } from 'services/api';
import { clientsQueryKeys } from 'services/queryKeys';

const getAuthToken = async () => {
	try {
		const token = await localForage.getItem('token');
		return token;
	} catch (error) {
		// eslint-disable-next-line no-console
		console.error('Error getting auth token:', error);
		return null;
	}
};

const axiosInstance = axios.create({
	baseURL: 'https://back.unitb.ua/api/v1',
});

export const clientsSliceApi = createApi({
	reducerPath: 'clients',
	baseQuery: fetchBaseQuery({ baseUrl: API_URL }),
	tagTypes: [clientsQueryKeys.clients()],
	endpoints: (builder) => ({
		// createClient: builder.mutation<IEmployee, IEmployeeCreateDTO>({
		createClient: builder.mutation<any, any>({
			queryFn: async (data) => {
				const authToken = await getAuthToken();
				try {
					const response = await axiosInstance.post('/clients', data, {
						headers: {
							Authorization: `Bearer ${authToken}`,
							'Content-Type': 'application/json',
						},
					});

					if (response.status !== 201) {
						throw new Error(response.statusText);
					}

					return response.data;
				} catch (error) {
					throw error;
				}
			},
			invalidatesTags: [clientsQueryKeys.clients()],
		}),
		getClients: builder.query({
			queryFn: async (queryParams) => {
				const authToken = await getAuthToken();
				try {
					const response = await axiosInstance.get('/clients', {
						params: new URLSearchParams(queryParams),
						headers: {
							Authorization: `Bearer ${authToken}`,
							'Content-Type': 'application/json',
						},
					});

					if (response.status !== 200) {
						throw new Error(response.statusText);
					}

					return response;
				} catch (error) {
					throw error;
				}
			},
			providesTags: (result) => (result ? [clientsQueryKeys.clients(), ...result.data.map(({ id }) => clientsQueryKeys.client(id))] : []),
		}),
		getClientOrders: builder.query({
			// !TODO: fix this once BE is ready
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			query: ({ id, queryParams }: { id: string; queryParams: string }) => '/orders',
			providesTags: (result, _, { id }) =>
				result
					? [clientsQueryKeys.clients(), clientsQueryKeys.client(id), ...result.map((orders) => clientsQueryKeys.client(orders.id))]
					: [],
			transformResponse: (response, _, { id, queryParams }) => {
				const searchParams = new URLSearchParams(decodeURI(queryParams));
				const queryParamsEntries = Array.from(searchParams.entries());

				return filterOrdersByClientAndFilters(id, response as Record<string, string>[], queryParamsEntries);
			},
		}),
		getSegments: builder.query({
			queryFn: async (queryParams) => {
				const authToken = await getAuthToken();
				try {
					const response = await axiosInstance.get('/segments', {
						params: new URLSearchParams(queryParams),
						headers: {
							Authorization: `Bearer ${authToken}`,
							'Content-Type': 'application/json',
						},
					});

					if (response.status !== 200) {
						throw new Error(response.statusText);
					}

					return response.data;
				} catch (error) {
					throw error;
				}
			},
		}),
		getContractsOptions: builder.query({
			query: () => '/contracts',
			transformResponse: (response) => {
				const unique = [];

				(response as Record<string, string>[]).forEach((contract) => {
					const isDuplicate = unique.includes(contract['Наименование']);

					if (!isDuplicate && !!contract['Наименование']) {
						unique.push(contract['Наименование']);

						return true;
					}

					return false;
				});

				return unique;
			},
		}),
		getClientsOptions: builder.query<unknown, string>({
			queryFn: async (queryParams) => {
				const authToken = await getAuthToken();
				try {
					const response = await axiosInstance.get('/clients/all', {
						params: new URLSearchParams(queryParams),
						headers: {
							Authorization: `Bearer ${authToken}`,
							'Content-Type': 'application/json',
						},
					});

					if (response.status !== 200) {
						throw new Error(response.statusText);
					}

					return response.data;
				} catch (error) {
					throw error;
				}
			},
		}),
		getClientById: builder.query<IClientData, string>({
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			queryFn: async (id) => {
				const authToken = await getAuthToken();
				try {
					const response = await axiosInstance.get(`/clients/${id}`, {
						headers: {
							Authorization: `Bearer ${authToken}`,
							'Content-Type': 'application/json',
						},
					});

					if (response.status !== 200) {
						throw new Error(response.statusText);
					}

					return response.data;
				} catch (error) {
					throw error;
				}
			},
			providesTags: (_, __, id) => [clientsQueryKeys.client(id)],
		}),
		getContractList: builder.query<unknown, string>({
			queryFn: async (clientId) => {
				const authToken = await getAuthToken();
				try {
					const response = await axiosInstance.get(`/contracts/client/${clientId}`, {
						headers: {
							Authorization: `Bearer ${authToken}`,
							'Content-Type': 'application/json',
						},
					});

					if (response.status !== 200) {
						throw new Error(response.statusText);
					}

					return response.data;
				} catch (error) {
					throw error;
				}
			},
		}),
		getClientsFiltersData: builder.query<IClientsFilters, string>({
			queryFn: async () => {
				const authToken = await getAuthToken();
				try {
					const response = await axiosInstance.get('/clients/filters', {
						headers: {
							Authorization: `Bearer ${authToken}`,
							'Content-Type': 'application/json',
						},
					});

					if (response.status !== 200) {
						throw new Error(response.statusText);
					}

					return response;
				} catch (error) {
					throw error;
				}
			},
		}),
	}),
});

export const {
	useGetClientsQuery,
	useGetClientByIdQuery,
	useGetSegmentsQuery,
	useGetContractsOptionsQuery,
	useGetClientsOptionsQuery,
	useGetClientOrdersQuery,
	useCreateClientMutation,
	useGetContractListQuery,
	useGetClientsFiltersDataQuery,
} = clientsSliceApi;
