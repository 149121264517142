import ColorfulSelect from 'components/ColorfulSelect';
import { breakPoints } from 'const';
import { useCreatingMode } from 'contexts/CreatingModeProvider';
import { useOrderCart } from 'hooks/useOrderCart';
import { useOrderViewer } from 'hooks/useOrderViewer';
import React, { useEffect } from 'react';
import MediaQuery from 'react-responsive';
import { useGetServicesQuery } from 'store/reducers/orders/ordersSliceApi';

import RootButton from './RootButton';
import styles from './styles.module.css';
import type { ServiceOptionSelectFieldProps } from './types';
import { useBackdrop } from './useBackdrop';
import { findServiceById } from './utils';

const ServiceOptionSelectField = ({ id, placeholder }: ServiceOptionSelectFieldProps) => {
	const { activeSuborder, draftOrder, utils } = useOrderViewer();
	const { data: servicesList } = useGetServicesQuery(undefined);
	const { creatingMode } = useCreatingMode();

	const cart = useOrderCart();

	const isOnSplitPage = utils.isOnSuborderSplittingPage();
	const source = isOnSplitPage ? activeSuborder : draftOrder;

	const { ref } = useBackdrop();

	const handler = creatingMode ? cart.setServiceTypeInNewOrder : source.onSelectChangeServiceType;

	const getOptionsList = () => {
		return findServiceById(servicesList, id)?.map((item) => {
			return {
				id: item.id,
				title: item.title,
				services: item.services,
			};
		});
	};

	const findService = (services) => {
		if (Array.isArray(services)) {
			const service = services.find((item) => item.id === id);
			return service;
		} else if (typeof services === 'object' && services !== null) {
			for (const key in services) {
				if (services.hasOwnProperty(key) && key.includes(id)) {
					if (services[key].id === id) {
						return services[key];
					}
				}
			}
		}
	};

	const services = creatingMode ? cart.createOrderDataState.services : isOnSplitPage ? activeSuborder.services : draftOrder.original.services;

	const findServiseTypeById = () => {
		return findService(services)?.type;
	};

	const getValueForType = () => {
		return findServiseTypeById() !== undefined && (findService(services).firstType || findServiseTypeById()[0] || findServiseTypeById());
	};

	const value = getOptionsList() !== undefined && getOptionsList()[0];
	useEffect(() => {
		if (!findService(services)?.firstType) return handler(id, getOptionsList()?.[0]);
	}, []);

	return (
		<>
			<MediaQuery minWidth={breakPoints.MOBILE}>
				<ColorfulSelect
					closeOnSelect
					popupHeaderTitle="Виберіть тип послуги"
					popupHeaderClassName={styles.popupHeader}
					value={getValueForType()}
					placeholder={placeholder}
					// @ts-ignore
					rootButton={(props) => <RootButton {...props} disabled={value === undefined} title={placeholder} />}
					options={getOptionsList() || []}
					// @ts-ignore
					onSelect={(v) => handler(id, v)}
				/>
			</MediaQuery>
			{/* <MediaQuery maxWidth={breakPoints.MOBILE - 1}>
				<Select
					disabled={!options?.length}
					// @ts-ignore
					wrapperClassName={styles.selectField}
					// @ts-ignore
					value={value}
					// @ts-ignore
					setValue={(v) => handler(id, v)}
					onClick={toggleVisibility}
					onClose={hide}
					placeholder={placeholder}
					valuesList={options}
					dropdownHeader="Виберіть тип послуги"
				/>
			</MediaQuery> */}

			<div ref={ref} className={styles.backdrop} />
		</>
	);
};

export default ServiceOptionSelectField;
