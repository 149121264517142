import 'static/styles/style.css';

import clsx from 'clsx';
import { breakPoints } from 'const';
import { DOTS, usePagination } from 'hooks/usePagination';
import React from 'react';
import MediaQuery, { useMediaQuery } from 'react-responsive';
import { useSearchParams } from 'react-router-dom';
import { ReactComponent as Arrow } from 'static/images/arrow-left.svg';

import styles from './style.module.css';
import type { IProps } from './types';

const Pagination: React.FC<IProps> = ({ previousPage, nextPage, pageIndex: currentPage, pagesCount, siblingCount = 2, goToPage }) => {
	const [searchParams, setSearchParams] = useSearchParams();

	const isMobile = useMediaQuery({ query: `(max-width: ${breakPoints.MOBILE - 1}px)` });
	const isTablet = useMediaQuery({ query: `(min-width: ${breakPoints.MOBILE}px)` });
	const siblings = isMobile ? 0 : isTablet ? 1 : siblingCount;

	const pages = usePagination({
		currentPage: currentPage + 1,
		pagesCount,
		siblingCount: siblings,
		skip: isMobile,
	});

	const lastPage = pages.at(-1);
	const isFirstPage = currentPage === 0;
	const isLastPage = currentPage + 1 === lastPage;

	const onNext = () => {
		if (isLastPage) return;

		nextPage();
	};

	const onPrevious = () => {
		if (isFirstPage) return;

		previousPage();
	};

	const onCertainPage = (pageNumber: number) => {
		if (pageNumber !== currentPage) {
			goToPage(pageNumber);
			const queryParams = new URLSearchParams(searchParams);
			queryParams.set('page', String(pageNumber + 1));
			setSearchParams(queryParams);
		}
	};

	return (
		<div data-pagination className={styles.paginationWrapper}>
			<button
				type="button"
				disabled={isFirstPage}
				className={clsx(styles.navigationLink, 'text-sm-medium', { [styles.outlinedControlButton]: isMobile })}
				onClick={onPrevious}
			>
				<Arrow style={{ stroke: '#475467' }} />
				<MediaQuery minWidth={breakPoints.MOBILE}>Назад</MediaQuery>
			</button>

			<div key={currentPage} className={styles.pageNumbers}>
				{isMobile && (
					<p className={clsx('text-sm-regular', 'color-grey-700')}>
						<span>Сторінка</span>&nbsp;
						<span className={clsx('text-sm-medium')}>{currentPage + 1}</span>&nbsp;<span>з</span>&nbsp;
						<span className={clsx('text-sm-medium')}>{pagesCount}</span>
					</p>
				)}

				{!isMobile &&
					pages.map((page, idx) => (
						<a
							key={page === DOTS ? page + idx : page}
							className={clsx('text-sm-medium', { [styles.currentPage]: currentPage === Number(page) - 1 })}
							onClick={() => onCertainPage(Number(page) - 1)}
						>
							<span className={styles.pageNumber} style={{ backgroundColor: currentPage === Number(page) - 1 && '#f9fafb' }}>
								{page}
							</span>
						</a>
					))}
			</div>

			<button
				type="button"
				disabled={isLastPage}
				className={clsx(styles.navigationLink, 'text-sm-medium', { [styles.outlinedControlButton]: isMobile })}
				onClick={onNext}
			>
				<MediaQuery minWidth={breakPoints.MOBILE}>Вперед</MediaQuery>

				<Arrow style={{ stroke: '#475467' }} className={styles.nextArrow} />
			</button>
		</div>
	);
};

export default Pagination;
