/* eslint-disable @typescript-eslint/no-explicit-any */
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import localForage from 'localforage';
import type { IEmployee, IEmployeeCreateDTO, IEmployeePathDTO, IInfoAboutMe } from 'models/IUser';
import { API_URL } from 'services/api';
import { usersQueryKeys } from 'services/queryKeys';

import apiClient from '../auth/apiClient';

const getAuthToken = async () => {
	try {
		const token = await localForage.getItem('token');
		return token;
	} catch (error) {
		// eslint-disable-next-line no-console
		console.error('Error getting auth token:', error);
		return null;
	}
};

export const usersSliceApi = createApi({
	reducerPath: 'users',
	baseQuery: fetchBaseQuery({ baseUrl: API_URL }),
	tagTypes: [usersQueryKeys.users()],
	endpoints: (builder) => ({
		getEmployees: builder.query<IEmployee[], string>({
			queryFn: async (queryParams) => {
				const authToken = await getAuthToken();
				try {
					const response = await apiClient.get('/users', {
						params: queryParams,
						headers: {
							Authorization: `Bearer ${authToken}`,
							'Content-Type': 'application/json',
						},
					});

					if (response.status !== 200) {
						throw new Error(response.statusText);
					}

					return response.data;
				} catch (error) {
					throw error;
				}
			},
			providesTags: (result) =>
				result ? [usersQueryKeys.users(), usersQueryKeys.usersOnly(), ...result.map(({ id }) => usersQueryKeys.user(id))] : [],
		}),
		getEmployeeById: builder.query<IEmployee, string>({
			queryFn: async (id) => {
				const authToken = await getAuthToken();
				try {
					const response = await apiClient.get(`/users/${id}`, {
						headers: {
							Authorization: `Bearer ${authToken}`,
							'Content-Type': 'application/json',
						},
					});

					if (response.status !== 200) {
						throw new Error(response.statusText);
					}

					return response.data;
				} catch (error) {
					throw error;
				}
			},
			providesTags: (_, __, id) => [usersQueryKeys.user(id)],
		}),
		createEmployee: builder.mutation<IEmployee, IEmployeeCreateDTO>({
			queryFn: async (data) => {
				const authToken = await getAuthToken();
				try {
					const response = await apiClient.post('/users', data, {
						headers: {
							Authorization: `Bearer ${authToken}`,
							'Content-Type': 'application/json',
						},
					});

					if (response.status !== 201) {
						throw new Error(response.statusText);
					}

					return response.data;
				} catch (error) {
					throw error;
				}
			},
			invalidatesTags: [usersQueryKeys.usersOnly()],
		}),
		updateEmployee: builder.mutation<IEmployee, IEmployeePathDTO>({
			query: (employee) => ({
				url: `/users/${employee.id}`,
				method: 'PUT',
				body: employee,
			}),
			invalidatesTags: [usersQueryKeys.usersOnly()],
			async onQueryStarted(employee, { dispatch, queryFulfilled }) {
				const optimisticOrderUpdate = dispatch(
					usersSliceApi.util.updateQueryData('getEmployeeById', String(employee.id), (draft) => {
						Object.assign(draft ?? {}, employee);
					}),
				);
				try {
					await queryFulfilled;
				} catch {
					optimisticOrderUpdate.undo();
				}
			},
		}),
		getManagers: builder.query({
			query: () => '/managers',
			providesTags: () => [usersQueryKeys.user('MANAGERS')],
		}),
		getManagersOptions: builder.query({
			query: () => '/managers',
			transformResponse: (response) =>
				(response as Record<string, string>[])?.map((manager) => ({
					label: manager['Наименование'],
					value: manager['Наименование'],
				})),
		}),
		getWarehouses: builder.query<unknown, string>({
			query: () => '/warehouses',
			providesTags: () => [usersQueryKeys.user('WAREHOUSES')],
			transformResponse: (response) =>
				(response as Record<string, string>[])?.map((warehouse) => ({
					label: warehouse['Наименование'],
					value: warehouse['Id'],
				})),
		}),
		getOrganizations: builder.query<unknown, string>({
			query: () => '/organizations',
			providesTags: () => [usersQueryKeys.user('ORGANIZATIONS')],
			transformResponse: (response) =>
				(response as Record<string, string>[])?.map((org) => ({
					label: org['Наименование'],
					value: org['ЗначениеId'],
				})),
		}),
		getInfoAboutMe: builder.query<IInfoAboutMe, string>({
			queryFn: async (queryParams) => {
				const authToken = await getAuthToken();
				try {
					const response = await apiClient.get('/auth/me', {
						params: queryParams,
						headers: {
							Authorization: `Bearer ${authToken}`,
							'Content-Type': 'application/json',
						},
					});

					if (response.status !== 200) {
						throw new Error(response.statusText);
					}

					return response;
				} catch (error) {
					throw error;
				}
			},
		}),
	}),
});

export const {
	useGetEmployeeByIdQuery,
	useGetEmployeesQuery,
	useUpdateEmployeeMutation,
	useGetManagersQuery,
	useGetWarehousesQuery,
	useGetOrganizationsQuery,
	useGetManagersOptionsQuery,
	useCreateEmployeeMutation,
	useGetInfoAboutMeQuery,
} = usersSliceApi;
