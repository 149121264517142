import 'static/styles/style.css';

import clsx from 'clsx';
import Button from 'components/Button';
import EmptyOrder from 'components/OrderCreatePageComponents/EmptyOrder';
import Spinner from 'components/Spinner';
import Table from 'components/Table';
import MobileHeader from 'components/Table/TableComponents/MobileHeader';
import { breakPoints, ROUTES_URLS } from 'const';
import { useOrderContext } from 'contexts/OrderProvider';
import { useOrderCart } from 'hooks/useOrderCart';
import { useOrderViewer } from 'hooks/useOrderViewer';
import ActionButton from 'layouts/PageLayout/ActionButton';
import React, { lazy, Suspense, useState } from 'react';
import { useSelector } from 'react-redux';
import MediaQuery from 'react-responsive';
import { useNavigate, useParams } from 'react-router-dom';
import PlusIcon from 'static/images/plus.svg';
import SaveIcon from 'static/images/save.svg';
import XCloseIcon from 'static/images/x-close.svg';
import { useCreateOrderMutation } from 'store/reducers/orders/ordersSliceApi';
import { prepareUrl } from 'utils/shared';

import styles from './styles.module.css';
import { IProps } from './types';

const OrderMobileTable = lazy(() => import('components/Table/MobileViews/OrderMobileTable'));
const ChangeProductPricePanel = lazy(() => import('components/ChangeProductPricePanel'));

const OrderProducts: React.FC<IProps> = ({ creatingMode }) => {
	const navigate = useNavigate();
	const { id } = useParams();
	const [createOrder, { isLoading }] = useCreateOrderMutation();

	const [isLoadingPage, setIsLoadingPage] = useState(false);
	// @ts-ignore
	const createOrderData = useSelector((state) => state.order.createOrderData);

	const { toggleSelectedItems, exitChangePriceMode, isChangePriceMode, productColumns, pinColumns } = useOrderContext();

	const { api, utils, draftOrder } = useOrderViewer();

	const orderHasSubOrders = draftOrder.suborders.length > 1;
	const cart = useOrderCart();

	const navigateToSelectProduct = () => {
		setIsLoadingPage(true);
		const url = prepareUrl(creatingMode ? ROUTES_URLS.CREATE_ORDER_SELECT_PRODUCT : ROUTES_URLS.ORDER_SELECT_PRODUCT, { id });

		navigate(url);
	};

	const handleCreateOrder = async () => {
		const transformedProducts = cart.createOrderDataState?.products.map((item) => ({
			productId: item.id,
			amount: item.amount || 1,
			price: item.price || Number(item.prices[0].price),
		}));

		const transformedServices = Object?.keys(cart.createOrderDataState?.services)?.map((key) => {
			const service = cart.createOrderDataState?.services[key];
			// @ts-ignore
			if (service.workType === undefined) {
				return {
					serviceId: service.id,
					amount: service.quantity,
					price: service.price,
				};
			} else {
				return {
					// @ts-ignore
					serviceId: service.workType.id,
					amount: service.quantity,
					price: service.price,
				};
			}
		});

		await createOrder({
			note: '',
			clientId: cart.createOrderDataState.client.value,
			contractId: cart.createOrderDataState.contract.value,
			responsibleId: cart.createOrderDataState.responsible.value,
			organizationId: cart.createOrderDataState.organization.value,
			stockId: cart.createOrderDataState.stock.value,
			typePriceId: '82dd3937-2316-11ea-80d5-8107dcf40211',
			products: transformedProducts,
			services: transformedServices,
		});
		await cart.clearCreateOrderData();
		await draftOrder.clearDraftOrderDataMemoized();
		const url = prepareUrl(ROUTES_URLS.ORDERS);

		await navigate(url);
	};

	const isEmptyOrder = !draftOrder.productsCount && !cart.createOrderDataState?.products;

	if (isEmptyOrder) {
		return <EmptyOrder />;
	}

	const disableBtn =
		!!createOrderData?.client &&
		!!createOrderData?.organization &&
		!!createOrderData?.contract &&
		!!createOrderData?.stock &&
		!!createOrderData?.responsible;

	return (
		<>
			<Suspense fallback={<Spinner />}>{isChangePriceMode && <ChangeProductPricePanel className={styles.changeProductPricePanel} />}</Suspense>
			{(isLoading || isLoadingPage) && <Spinner />}

			<Table
				selectable
				allData={draftOrder.products?.length > 0 ? draftOrder.products : cart.createOrderDataState?.products}
				columns={productColumns}
				pageType="order"
				pinColumns={pinColumns}
				overrideToggleAllRowSelectedProps={utils.overrideAllSelection}
				defineRowDisabledForSelection={utils.assignIsDisabledLabel}
				mobileViewComponent={({ row }) => <OrderMobileTable row={row} onEditProductClick={() => {}} />}
				onChangeSelection={toggleSelectedItems}
				insertSelectableAt={1}
				className={styles.orderProductsTable}
				mobileHeader={({ headerGroups }) => (
					<Suspense fallback={<Spinner />}>
						<MobileHeader
							gridCustomLayout="44px 44px auto"
							headerGroups={headerGroups}
							renderHeaders={['.', 'selection', 'title']}
							justify="flex-start"
							className={styles.mobileHeader}
						/>
					</Suspense>
				)}
			/>

			<MediaQuery maxWidth={breakPoints.MOBILE - 1}>
				<Suspense>
					<ActionButton
						variant="default"
						onClick={navigateToSelectProduct}
						text="Додати товар"
						icon={PlusIcon}
						className={styles.addProductButton}
					/>
					<div className="safe-area-bottom" />
				</Suspense>
			</MediaQuery>

			{isChangePriceMode && (
				<MediaQuery minWidth={breakPoints.MOBILE}>
					<div className={clsx(styles.fixedBtn, styles.priceChangeControls)}>
						<Button text="Скасувати" variant="rounded" icon={XCloseIcon} onClick={exitChangePriceMode} className={styles.cancelButton} />
						<Button text="Зберегти" variant="rounded" icon={SaveIcon} className={styles.saveButton} />
					</div>
				</MediaQuery>
			)}

			{!isChangePriceMode && (
				<MediaQuery minWidth={breakPoints.MOBILE}>
					<div className={styles.fixedBtn}>
						{!orderHasSubOrders && (
							<Button
								text="Додати товар"
								variant="rounded"
								icon={PlusIcon}
								onClick={navigateToSelectProduct}
								className={styles.addProductButton}
							/>
						)}
						{!id && (
							<Button
								text="Створити заявку"
								variant="rounded"
								background="var(--primary-600)"
								className={styles.btn}
								onClick={handleCreateOrder}
								disableClassName={styles.disableBtn}
								disabled={!disableBtn}
							/>
						)}
					</div>
				</MediaQuery>
			)}

			{api.isProcessing && <Spinner />}
		</>
	);
};

export default OrderProducts;
