import { AutocompleteFieldForClient, AutocompleteFieldWithOnSelectAction } from 'components/FormComponents/Inputs/inputs';
import { SelectOption } from 'components/Select/types';
import SpinnerV2 from 'components/Spinner-v2';
import { ROUTES_URLS } from 'const';
import { useCreatingMode } from 'contexts/CreatingModeProvider';
import { useAutocompleteOptions, useContractList } from 'hooks/clients';
import { useOrder } from 'hooks/orders';
import React, { useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux/es/hooks/useSelector';
import { useNavigate } from 'react-router-dom';
import { prepareUrl } from 'utils/shared';
import { isNullish, isObject } from 'utils/type-guards';

import styles from './styles.module.css';
import type { IProps } from './types';

const AutocompleteFilters: React.FC<IProps> = ({ items }) => {
	// @ts-ignore
	const createOrderData = useSelector((state) => state.order.createOrderData);
	const { clients, organizations, warehouses, simpleManagers, isLoading } = useAutocompleteOptions();
	const navigate = useNavigate();
	const { order } = useOrder();
	const [clientId, setClientId] = useState(createOrderData?.client?.value || order?.client?.id);
	const { contractList } = useContractList(clientId);
	const [contractListForClient, setContractListForClient] = useState(contractList);

	const { watch } = useFormContext();
	const formData = watch();

	const { creatingMode } = useCreatingMode();

	useEffect(() => {
		if (order?.client?.id) {
			setClientId(order?.client?.id);
		} else {
			setClientId(createOrderData?.client?.value);
		}
	}, [createOrderData?.client, order]);

	useEffect(() => {
		setContractListForClient(contractList);
	}, [contractList]);

	const orderClientName = order?.client?.name || order?.client?.organizationName;

	const stock = order?.stock && { label: order.stock.title, value: order.stock.id };
	const manager = order?.responsible && { label: order.responsible.name, value: order.responsible['1c_uuid'] };

	const formFields = useMemo(
		() => [
			{
				name: 'client',
				label: 'Клієнт',
				valuesList: clients,
				onClick: (option: SelectOption) => {
					if (!option) {
						return;
					}
					if (isObject(option) && isNullish(option.value)) {
						return;
					}
					const clientIdi = option.value;
					const url = prepareUrl(ROUTES_URLS.CLIENT_PAGE, { id: clientIdi });

					navigate(url);
				},
				forceFocus: true,
				value: orderClientName ? orderClientName : createOrderData.client?.label,
				component: AutocompleteFieldForClient,
				placeholder: 'Виберіть клієнта',
			},
			{
				name: 'organization',
				label: 'Організація',
				valuesList: organizations,
				forceFocus: true,
				value: order?.organization?.title ? order?.organization?.title : formData.organization,
				component: AutocompleteFieldWithOnSelectAction,
				placeholder: 'Виберіть організацію',
			},
			{
				name: 'contract',
				label: 'Договір',
				valuesList: contractListForClient,
				forceFocus: true,
				value: order?.contract?.title ? order?.contract?.title : createOrderData.contract?.label,
				component: AutocompleteFieldWithOnSelectAction,
				placeholder: 'Виберіть договір',
			},
			{
				name: 'stock',
				label: 'Склад',
				valuesList: warehouses,
				forceFocus: true,
				value: stock ? stock : formData?.stock,
				component: AutocompleteFieldWithOnSelectAction,
				placeholder: 'Виберіть склад',
			},
			{
				name: 'responsible',
				label: 'Менеджер',
				valuesList: simpleManagers,
				forceFocus: true,
				value: manager ? manager : formData?.responsible,
				component: AutocompleteFieldWithOnSelectAction,
				placeholder: 'Виберіть менеджера',
			},
		],
		[clients, organizations, contractListForClient, warehouses, simpleManagers],
	);

	const formFieldsItems = items ?? formFields;
	const fieldsCount = formFieldsItems.length;

	return (
		<form className={styles.form} style={{ '--columns': fieldsCount } as React.CSSProperties}>
			{formFieldsItems.map(({ component: Component, ...field }) => (
				<>{isLoading && creatingMode ? <SpinnerV2 /> : <Component key={field.name} {...field} fieldClassName={styles.formField} />}</>
			))}
		</form>
	);
};

export default AutocompleteFilters;
