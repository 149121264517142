/* eslint-disable react/prop-types */
import 'static/styles/style.css';

import clsx from 'clsx';
import BreadCrumbs from 'components/BreadCrumbs';
import { Breadcrumb } from 'components/BreadCrumbs/types';
import Button from 'components/Button';
import Chip from 'components/Chip';
import EmptyOrderGate from 'components/OrderPageComponents/(EmptyStateGates)/EmptyOrderGate';
import EmptyProductsGate from 'components/OrderPageComponents/(EmptyStateGates)/EmptyProductsGate';
import FullScreenGate from 'components/OrderPageComponents/(VisibilityGates)/FullScreenGate';
import Payable from 'components/OrderPageComponents/Payable';
import Spacer from 'components/Spacer';
import Spinner from 'components/Spinner';
import MobileHeader from 'components/Table/TableComponents/MobileHeader';
import { TablistInMemory } from 'components/Tablist';
import Panel from 'components/Tablist/Panel';
import { breakPoints, ROUTES_URLS } from 'const';
import { useCreatingMode } from 'contexts/CreatingModeProvider';
import { useOrderContext } from 'contexts/OrderProvider';
import { useOrder } from 'hooks/orders';
import { useOrderViewer } from 'hooks/useOrderViewer';
import BottomPanel from 'layouts/PageLayout/BottomPanel';
import PageContentSkeleton from 'layouts/PageLayout/PageContentSkeleton';
import React, { lazy, Suspense, useCallback, useMemo } from 'react';
import { FormProvider } from 'react-hook-form';
import MediaQuery from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import ReturnIcon from 'static/images/corner-down-right.svg';
import LockIcon from 'static/images/lock.svg';
import Plus from 'static/images/plus.svg';
import MultiFileICon from 'static/images/save-multi.svg';
import XCloseIcon from 'static/images/x-close.svg';
import { bakeOrderBreadcrumbs } from 'utils/orders';
import { prepareUrl } from 'utils/shared';

import ControlPanel from './ControlPanel';
import { useOrderMetaDataForm } from './hooks/useOrderMetaForm';
import { useTransferModals } from './hooks/useTransferModals';
import MetaData from './MetaData';
import Services from './Services';
import styles from './styles.module.css';
import { extendRowProps, useButtonTitle } from './utils';

const RefreshActions = lazy(() => import('components/OrderCreatePageComponents/ProductSelectTopBar/RefreshActions'));
const OrdersNavigator = lazy(() => import('components/OrderCreatePageComponents/OrdersNavigator'));
const OrderMobileTable = lazy(() => import('components/Table/MobileViews/OrderMobileTable'));
const Table = lazy(() => import('components/Table'));

const SplitOrder: React.FC = () => {
	const navigate = useNavigate();
	const { id, order } = useOrder();

	const form = useOrderMetaDataForm();
	const transferModal = useTransferModals();
	const { creatingMode } = useCreatingMode();

	const { api, utils, stats, activeSuborder, forceRerenderKey, suborders, regularSubordersLimit, booleans, paintToning, cancelSplittingSafe } =
		useOrderViewer();

	// const { columns, pinColumns } = useOrderColumns({
	// 	onInputChange: activeSuborder.onInputChangeInProducts,
	// 	adjustSaveKey: '/order',
	// 	rerenderKey: forceRerenderKey,
	// });

	const {
		selectedProducts,
		regularServices,
		paintToningServices,
		products,
		index: activeTabIndex,
		activeCategoryIndex,
		selectProducts,
	} = activeSuborder;

	const { productColumns, pinColumns } = useOrderContext();

	const orderContext = useOrderContext();

	const hasSelectedProducts =
		suborders.allSuborders.length >= 7
			? false
			: booleans.isOnProductsTabInActiveSuborder
			? selectedProducts.length > 0
			: orderContext.selectedItems.length > 0;

	const totalOrdersCount = suborders.allSuborders.length;

	/*
	 * once there is a paint toning suborder ---> make offset for available buttons
	 */
	const ordersCount = utils.hasOnePaintToningSuborder() ? totalOrdersCount - 1 : totalOrdersCount;
	const resolvedOrdersCount = Math.min(ordersCount, regularSubordersLimit);

	const { totalPrice } = stats;

	const buttonTitles = useButtonTitle({
		index: activeTabIndex,
		ordersCount: resolvedOrdersCount,
	});

	const buttonTitlesList = buttonTitles.length > regularSubordersLimit ? buttonTitles.slice(0, -1) : buttonTitles;

	const crumbs: Breadcrumb[] = [{ label: 'Заявки', href: prepareUrl(ROUTES_URLS.ORDERS) }, bakeOrderBreadcrumbs(order)];

	const activeSuborderProductsCount = products.length;
	const activeSuborderServicesCount = regularServices.length + paintToningServices.length;

	const goToAddProductPage = useCallback(() => {
		const url = prepareUrl(creatingMode ? ROUTES_URLS.CREATE_ORDER_SELECT_PRODUCT : ROUTES_URLS.ORDER_SELECT_PRODUCT, { id });

		navigate(url);
	}, []);

	const saveOrder = async () => {
		await api.async.requestSaveSplittingSafe();
	};

	const handlePaintToningButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.stopPropagation();

		paintToning.createSuborder();
	};

	const handleTransferButtonClick = (index: number) => (event: React.MouseEvent<HTMLButtonElement>) => {
		event.stopPropagation();

		suborders.handlers.transferSafe({
			index,
			maybeServices: orderContext.selectedItems,
			dialogue: transferModal,
		});
	};
	const handleSuborderReservationButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.stopPropagation();

		api.async.requestReserveSuborderSafe();
	};

	const handleCategoryTabChange = (index: number) => {
		suborders.handlers.switchCategoryTab(index);
	};

	const handleSpittingCancel = useCallback(() => {
		cancelSplittingSafe();
	}, []);

	const actionButtons = useMemo(() => {
		return [
			{ id: '-1', title: 'Додати товар', icon: Plus, onClick: goToAddProductPage, background: 'var(--primary-500)' },
			...buttonTitlesList?.map((transferButton, idx) => ({
				id: idx,
				title: transferButton.label,
				icon: ReturnIcon,
				onClick: handleTransferButtonClick(transferButton.index),
				background: '#039855',
				hoverBg: '#027A48',
				className: styles.moveToOrder,
				disabled: !hasSelectedProducts,
			})),
			{
				id: 'paint-toning',
				title: 'Заявка на тонування',
				icon: ReturnIcon,
				onClick: handlePaintToningButtonClick,
				background: '#039855',
				hoverBg: '#027A48',
				className: styles.moveToOrder,
				disabled: !hasSelectedProducts,
			},
			{
				id: 'cancel-splitting',
				title: 'Скасувати розбиття',
				icon: XCloseIcon,
				onClick: handleSpittingCancel,
				background: 'var(--gray-400)',
				hoverBg: 'var(--gray-500)',
				className: styles.moveToOrder,
			},
		];
	}, [activeTabIndex, hasSelectedProducts, selectedProducts, order, buttonTitles]);

	const actionButtonsComponentsList = actionButtons.map(({ id: buttonId, title, ...restProps }) => {
		return <Button key={buttonId} text={title} variant="rounded" data-order={buttonId} {...restProps} />;
	});

	return (
		<Suspense fallback={<PageContentSkeleton />}>
			<FormProvider {...form}>
				<div className="main-wrapper">
					<div className={clsx('container', styles.container)}>
						<BreadCrumbs crumbs={crumbs} />

						<FullScreenGate>
							<MediaQuery maxWidth={breakPoints.MOBILE - 1}>
								<div className={styles.refreshActionsWrapper}>
									<RefreshActions />
								</div>
							</MediaQuery>
						</FullScreenGate>

						<div className={styles.totalPriceWrapper}>
							<div className={styles.orderSwitchControls}>
								<OrdersNavigator />
							</div>

							<FullScreenGate>
								<MediaQuery minWidth={breakPoints.MOBILE}>
									<div className={styles.chipsAndPriceWrapper}>
										<div className={styles.chipsWrapper}>
											<Chip title={`Вага - ${order.weight} кг`} />
											<Chip title={`Об’єм - ${order.volume} м³`} />
										</div>

										<Payable price={totalPrice} className={styles.totalPrice} />
									</div>
								</MediaQuery>
								<MediaQuery maxWidth={breakPoints.MOBILE - 1}>
									<div className={styles.chipsWrapper}>
										<Chip title={`Вага - ${order.weight} кг`} />
										<Chip title={`Об’єм - ${order.volume} м³`} />
									</div>

									<Payable price={totalPrice} className={styles.totalPrice} />
								</MediaQuery>

								<div className={styles.suborderControlsWrapper}>
									<Button onClick={saveOrder} text="Зберегти" variant="rounded" background="var(--pink)" icon={MultiFileICon} />

									{activeTabIndex > 0 && (
										<Button
											onClick={handleSuborderReservationButtonClick}
											text={booleans.isActiveSuborderReserved ? 'В резерві' : `Резерв заявки ${activeTabIndex}`}
											variant="rounded"
											background="var(--error-50)"
											icon={LockIcon}
											className={clsx(styles.reserv, { [styles.active]: booleans.isActiveSuborderReserved })}
										/>
									)}
								</div>
							</FullScreenGate>
						</div>

						<FullScreenGate>
							<MetaData />
						</FullScreenGate>

						<Spacer height="14px" />

						<EmptyOrderGate>
							<TablistInMemory slot={<ControlPanel />} onTabChange={handleCategoryTabChange} activeIndex={activeCategoryIndex}>
								<Panel title="Додані товари" itemsCount={activeSuborderProductsCount} className={styles.panel}>
									<EmptyProductsGate>
										<Table
											selectable
											key={forceRerenderKey}
											pinColumns={pinColumns}
											className={styles.table}
											allData={products}
											pageType="order"
											extendRowProps={extendRowProps}
											defineRowDisabledForSelection={utils.assignIsDisabledLabel}
											overrideToggleAllRowSelectedProps={utils.overrideAllSelection}
											mobileViewComponent={({ row }) => <OrderMobileTable row={row} />}
											columns={productColumns}
											onChangeSelection={selectProducts}
											mobileHeader={({ headerGroups }) => (
												<MobileHeader headerGroups={headerGroups} renderHeaders={['.', 'brand', 'title']} />
											)}
										/>
									</EmptyProductsGate>
								</Panel>
								<Panel title="Додані послуги" itemsCount={activeSuborderServicesCount} className={styles.panel}>
									<Services />
								</Panel>
							</TablistInMemory>
						</EmptyOrderGate>

						<MediaQuery minWidth={breakPoints.MOBILE}>
							<div className={styles.actionButtonsWrapper}>{actionButtonsComponentsList}</div>
						</MediaQuery>
					</div>

					{/* bottom controls */}
					<MediaQuery maxWidth={breakPoints.MOBILE - 1}>
						<BottomPanel className={clsx(styles.bottomPanel, { [styles.odd]: suborders.allSuborders.length === 2 })}>
							{actionButtonsComponentsList}
						</BottomPanel>
					</MediaQuery>
				</div>

				<MediaQuery maxWidth={breakPoints.MOBILE - 1}>
					<div className="safe-area-bottom" />
				</MediaQuery>

				{api.isProcessing && <Spinner />}
			</FormProvider>
		</Suspense>
	);
};

export default SplitOrder;
