import { useOrderCart } from 'hooks/useOrderCart';
import React, { createContext, PropsWithChildren, useCallback, useContext, useEffect, useState } from 'react';

const ModeContext = createContext(undefined);

export const CreatingModeProvider = ({ children }: PropsWithChildren) => {
	const [creatingMode, setCreatingMode] = useState(false);
	const cart = useOrderCart();
	const path = window.location.pathname;
	useEffect(() => {
		const handleRouteChange = () => {
			if (
				path !== '/order/create' &&
				path !== '/order/select-product' &&
				path !== '/order/create/new-order' &&
				path !== '/order/create/new-order/services'
			) {
				cart.clearCreateOrderData();
			}
		};

		window.addEventListener('popstate', handleRouteChange);

		handleRouteChange();
	}, [path]);

	const handleChangeCreatingMode = useCallback((value: boolean) => {
		setCreatingMode(value);
	}, []);

	return <ModeContext.Provider value={{ creatingMode, handleChangeCreatingMode }}>{children}</ModeContext.Provider>;
};

export const useCreatingMode = () => {
	const context = useContext(ModeContext);
	if (!context) {
		throw new Error('useMode must be used within a ModeProvider');
	}
	return context;
};
