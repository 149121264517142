import clsx from 'clsx';
import ClickAwayListener from 'components/ClickAwayListener';
import SpinnerV2 from 'components/Spinner-v2';
import { useOrderViewer } from 'hooks/useOrderViewer';
import React, { MouseEvent, useTransition } from 'react';
import { ReactComponent as PackageCheckIcon } from 'static/images/package-check.svg';
import { ReactComponent as PaintPourIcon } from 'static/images/paint.svg';
import { ReactComponent as ScissorsIcon } from 'static/images/scissors.svg';
import { ReactComponent as TruckIcon } from 'static/images/truck-white.svg';
import { ReactComponent as XCloseIcon } from 'static/images/x-close.svg';
import { useGetServicesQuery } from 'store/reducers/orders/ordersSliceApi';

import SelectorButton from './SelectorButton';
import styles from './styles.module.css';
import type { PopupAppearanceProps, ServiceSelectorsProps } from './types';

const iconMap = [TruckIcon, PackageCheckIcon, ScissorsIcon, PaintPourIcon];

const ServiceSelectors: React.FC<ServiceSelectorsProps> = ({ appearance, onClick, onAddOrder, ...restProps }) => {
	const { data: servicesList, isLoading, error } = useGetServicesQuery(undefined);
	const { utils } = useOrderViewer();
	const [, startTransition] = useTransition();

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const handleOnClick = (serviceName: any) => (event: MouseEvent<HTMLButtonElement>) => {
		const service = Object.entries(servicesList).find(([key]) => key === serviceName);
		onAddOrder(service);
		startTransition(() => {
			onClick?.(event);
		});
	};

	const isPopupAppearance = appearance === 'popup';

	if (isLoading) {
		return <SpinnerV2 />;
	}

	if (error) {
		return <p>Error loading services</p>;
	}

	const titles = Object.keys(servicesList).map((service) => service);
	const buttonList = titles.map((title, index) => {
		return (
			<li key={title}>
				<SelectorButton onClick={handleOnClick(title)} label={title} icon={iconMap[index % iconMap.length]} />
			</li>
		);
	});

	if (utils.hasDraftOrderSavedPaintToningService() && !utils.isDraftOrderOfRegularType()) {
		return null;
	}

	if (!isPopupAppearance) {
		return <ul className={clsx(styles.tablistControls)}>{buttonList}</ul>;
	}

	const { onClose } = restProps as PopupAppearanceProps;

	return (
		<div className={styles.backdrop}>
			<ClickAwayListener onClickAway={onClose}>
				<ul className={clsx(styles.tablistControls, styles.appearancePopup)}>
					{isPopupAppearance && (
						<li className={styles.header}>
							<p>Оберіть послугу</p>
							<button type="button" className={styles.xCloseButton} onClick={onClose}>
								<XCloseIcon />
							</button>
						</li>
					)}
					{buttonList}
				</ul>
			</ClickAwayListener>
		</div>
	);
};

export default ServiceSelectors;
