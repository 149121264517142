import { ORGANIZATIONS } from 'const';
/* eslint-disable @typescript-eslint/no-explicit-any */
import { IBaseClient } from 'models/IClient';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';
import {
	useGetClientByIdQuery,
	useGetClientOrdersQuery,
	useGetClientsOptionsQuery,
	useGetClientsQuery,
	useGetContractListQuery,
	useGetContractsOptionsQuery,
	useGetSegmentsQuery,
} from 'store/reducers/clients/clientsSliceApi';
import { selectAllClients } from 'store/reducers/clients/selectors';
import { useGetOrdersQuery } from 'store/reducers/orders/ordersSliceApi';
import { selectAllManagers, selectAllOrganizations, selectAllWarehouses } from 'store/reducers/users/selectors';
import { useGetEmployeesQuery, useGetManagersQuery, useGetOrganizationsQuery, useGetWarehousesQuery } from 'store/reducers/users/usersSliceApi';

export const useClients = () => {
	const [searchParams] = useSearchParams();

	const clients = useSelector(selectAllClients(searchParams.toString()));
	const managers = useSelector(selectAllManagers);
	const warehouses = useSelector(selectAllWarehouses);
	const organizations = useSelector(selectAllOrganizations);

	return {
		clients: clients.data.map((item) => ({ ...item, manager: item?.manager?.name })),
		managers,
		warehouses,
		organizations,
		meta: clients.meta,
	};
};

export const useClient = () => {
	const { id } = useParams<{ id: string }>();

	const { data: segmentsList } = useGetSegmentsQuery(undefined);
	const { data: rawClient } = useGetClientByIdQuery(id);
	const managers = useSelector(selectAllManagers);
	const organizations = useSelector(selectAllOrganizations);

	const [mappedClient] = useMemo(() => {
		const serializedClient = serializeClients(
			// @ts-ignore
			rawClient,
			managers,
			organizations as Record<string, string>[],
		);

		const unSerializeClient = rawClient[0];
		// @ts-ignore
		return serializedClient.map((client) => ({
			id: client?.id,
			edrpouCode: unSerializeClient['ЕДРПОУ'],
			ipnCode: unSerializeClient['ІПН'],
			organizationName: unSerializeClient['Наименование'],
			passport: 'ЕК 12345678',
			name: client?.name,
			responsible: client?.meta?.manager?.name,
			segment: client?.segment?.label,
			limit: Math.ceil(Math.random() * 40000),
			delay: 2,
			date: new Date(),
			// @ts-ignore
			manager: client.manager?.name,
			contractType: client.contractType,
			// @ts-ignore
			type: client.segment.title,
			address: 'м. Київ, вулиця Жилянськая, 25-а',
			phoneNumber: [{ number: '380503456789', type: 'Особистий' }],
			comment: client.comment,
		}));
	}, [rawClient, managers, organizations, segmentsList]);

	return mappedClient;
};

export const useClientOrders = () => {
	const { id } = useParams<{ id: string }>();
	const [searchParams] = useSearchParams();

	const { data: clients } = useGetClientsQuery('');
	const { data: warehouses } = useGetWarehousesQuery(undefined);
	const { data: managers } = useGetManagersQuery(undefined);
	const { data: organizations } = useGetOrganizationsQuery(undefined);

	const { data: orders } = useGetClientOrdersQuery({ id, queryParams: searchParams.toString() });

	if (!clients || !orders || !warehouses || !managers || !organizations) return null;

	const client = serializeClients(
		managers,
		// @ts-ignore
		// contracts,
		// @ts-ignore
		organizations,
	)[0];

	const data = {
		id,
		client,
		orders,
	};

	return data;
};

export const useClientMetaInfo = () => {
	const data = useClientOrders();
	const { client } = data ?? {};

	if (!client) {
		return {
			department: '',
			manager: '',
			facilities: [],
		};
	}

	return {
		department: client?.meta?.department,
		manager: { label: client?.meta?.manager?.name, value: client?.meta?.manager?.name },
		facilities: [],
	};
};

export const useClientsTableData = () => {
	const { clients, meta } = useClients();
	const { data: orders } = useGetOrdersQuery('');

	const clientsLength = meta?.total;
	const pageCount = meta?.last_page;
	const currentPage = meta?.current_page;

	const tableData = useMemo(() => {
		if (!clients || !orders) return null;

		return clients.map((client) => ({
			id: client?.id,
			client: client?.name || client?.organizationName,
			responsible: client?.meta?.manager?.name,
			segment: client?.segment?.title,
			limit: Math.ceil(Math.random() * 40000),
			delay: 2,
			manager: client.manager,
			contractType: client.contractType,
			comment: client.comment,
			edrpouCode: client.edrpouCode,
			clientHasOrder: orders?.data?.some((order) => order?.client?.id === client?.id),
			createdAt: client.createdAt,
			ordersCount: client.ordersCount,
			lastDeal: client.lastDeal,
			code: client.code,
		}));
	}, [clients, orders]);

	return { tableData, clientsLength, pageCount, currentPage };
};

export const useAutocompleteOptions = () => {
	const { data: warehouses = [], isFetching: isLoadingStock } = useGetWarehousesQuery(undefined);
	const { data: contracts, isFetching: isLoadingContracts } = useGetContractsOptionsQuery(undefined);
	const { data: clients, isFetching: isLoadingClients } = useGetClientsOptionsQuery(undefined);
	const { data: managers, isFetching: isLoadingManagers } = useGetEmployeesQuery(undefined);
	const organizations = ORGANIZATIONS;

	const simpleManagers = managers?.map((manager) => ({
		label: manager.name,
		value: manager['1c_uuid'],
	}));
	// @ts-ignore
	const simpleClients = clients?.map((client) => ({
		label: client.name,
		value: client.id,
	}));

	const isLoading = isLoadingStock || isLoadingContracts || isLoadingClients || isLoadingManagers;

	return useMemo(() => {
		return {
			clients: simpleClients || [],
			organizations: organizations || [],
			contracts: contracts || [],
			warehouses: warehouses || [],
			managers: managers || [],
			simpleManagers: simpleManagers || [],
			isLoading,
		};
	}, [clients, organizations, contracts, warehouses, managers, simpleManagers, isLoading]);
};

export const useClientSegments = () => {
	const { data: segments } = useGetSegmentsQuery(undefined);

	return segments ?? [];
};

export function serializeClients(rawClients: Record<string, string>[], managers?: Record<string, string>[], organization?: Record<string, string>[]) {
	const serialized: IBaseClient = {
		// @ts-ignore

		id: rawClients?.id,
		// @ts-ignore
		segment: rawClients.segment?.title,
		phone: '0951246788',
		// @ts-ignore

		name: rawClients?.name,
		registryDate: new Date().toLocaleString(),
		meta: {
			department: {
				label: '',
				value: '',
			},
			manager: 'clientManager',
		},
		organization: organization?.[0],
	} as any;

	return serialized;

	// if (isLegalClient) {
	// 	return {
	// 		...serialized,
	// 		edrpouCode: client['ЕДРПОУ'],
	// 		type: 'legal',
	// 	} as ILegalClient;
	// } else {
	// 	return {
	// 		...serialized,
	// 		type: 'individual',
	// 		passportNumber: '',
	// 		attachments: [],
	// 	} as IIndividualClient;
	// }
}

export const useContractList = (clientId: string) => {
	const { data, error, isLoading, refetch } = useGetContractListQuery(clientId, {
		skip: !clientId, // Skip the query if clientId is not provided
	});

	const [contractList, setContractList] = useState(null);
	const [contractError, setContractError] = useState(null);

	useEffect(() => {
		if (data) {
			// @ts-ignore
			const newData = data.map((item) => ({
				label: item.title,
				value: item.id,
			}));
			setContractList(newData);
		} else if (error) {
			setContractError(error);
		}
	}, [data, error]);

	return { contractList, contractError, isLoading, refetch };
};
