import type { SelectOption } from 'components/Select/types';
import { ORDER_NEWLY_CREATED_STATUS, ORDER_RESERVATION_STATUS } from 'const';
import type { ISingleOrder, Products, Service } from 'models/IOrder';
import type { IRegularService, IService } from 'models/IServices';
import { numberify, toArray, toPrecise, uuid } from 'utils/shared';
import { isObject, isString } from 'utils/type-guards';

import type { Product } from '../orders/types';
import type { Locker, Services, Suborder } from './types';

type CreateBlankSuborderParams = {
	orderId: string;
	number: number;
	stock: SelectOption;
	type?: Suborder['type'];
	index: number;
};

export const createBlankSuborder = ({ orderId, number, stock, type = 'regular', index }: CreateBlankSuborderParams): Suborder => {
	return {
		tempId: uuid(),
		products: [],
		services: {},
		orderId,
		selectedProducts: [],
		selectedServices: [],
		number,
		createdAt: new Date(),
		stock,
		type,
		status: ORDER_NEWLY_CREATED_STATUS,
		meta: {
			tabTitle: createTabTitleByType(type, index),
			isSaved: false,
			index,
		},
	};
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const patchProduct = (arr: Record<string, any>[], id: string, properties: Record<string, any>) => {
	const patchedProducts = arr.map((product) => {
		if (product.id !== id) {
			return product;
		}

		const entries = Object.entries(properties ?? {});

		const newProduct = entries.reduce(
			(targetProduct, [key, value]) => {
				let totalPrice: number | string = numberify(product.amount);

				if (key === 'amount') {
					const amount = numberify(value);
					const price = numberify(product['price']);

					totalPrice = toPrecise(amount * price, 2);

					targetProduct['sum'] = numberify(totalPrice);

					targetProduct['amount'] = value;
					// тут правимо ціну
					return targetProduct;
				}

				targetProduct[key] = numberify(value as string);

				return targetProduct;
			},
			{ ...product },
		);

		return newProduct;
	});

	return patchedProducts;
};

export const calculateServicesFee = (services?: Service) => {
	const servicesArr = Object.values(services ?? {});

	if (servicesArr.length === 0) return 0;

	const servicesTotalSum = servicesArr.reduce((total, service) => {
		const price = isString(service.price) ? parseFloat(service.price || '0') : service.price;
		const servicePrice = price * service.quantity;

		return (total += servicePrice);
	}, 0);

	return servicesTotalSum;
};

export const prepareBlankService = (rawService) => {
	const service: IRegularService = {
		id: rawService[1].id,
		serviceId: rawService[1].code,
		service: rawService[1].title,
		price: rawService.price || 0,
		quantity: 1,
		type: rawService[1].subCategories,
		workType: undefined,
		serviceType: 'regular',
	};

	return service;

	// const service: IPaintToningService = {
	// 	id: uuid(),
	// 	serviceId: rawService.value,
	// 	service: rawService.label,
	// 	price: '2500.00',
	// 	quantity: 1,
	// 	serviceType: rawService.type,
	// 	colorCode: '',
	// 	collection: '',
	// 	baseTone: '',
	// 	tare: '10л',
	// };

	// return service;
};

export const getPriceByTypePriceId = (data, typePriceId) => {
	const priceInfo = data?.prices?.find((price) => price.typePrice.id === typePriceId);
	return priceInfo ? priceInfo.price : null;
};

// ---- hydration helpers ---- //
export const createRootSuborder = (order: ISingleOrder): Partial<Suborder> => {
	const rootSuborder: Partial<Suborder> = {
		orderId: null,
		number: Number(order?.number),
		products: order?.products ?? [],
		// services:
		// 	order?.services?.reduce((acc, service) => {
		// 		// !TEMP id assignment
		// 		const id = service.id || uuid();

		// 		return { ...acc, [id]: { ...service, id } };
		// 	}, {}) ?? {},
		selectedProducts: [],
		tempId: uuid(),
	};

	return rootSuborder;
};

export const checkIfHasSubordersSavedOnServer = (order: ISingleOrder) => {
	const hasSuborders = !!order?.subOrders && order.subOrders.length > 0 && order.subOrders[0].orderId !== null;

	return hasSuborders;
};
export const checkIfHasServicesSavedOnServer = (order: ISingleOrder) => {
	const hasServices = !!order?.services && Array.isArray(order.services) && order?.services.length > 0;

	return hasServices;
};
export const checkIfHasInMemoryServices = (services: Services) => {
	const hasServices = Object.values(services ?? {}).filter((service) => service.isSaved === false).length > 0;

	return hasServices;
};

export const getRootOrderId = (order: ISingleOrder) => {
	return String(order?.id ?? order?.number);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getHydrateOrderStateData = (order: ISingleOrder, orderInState: any, stateServices: Services) => {
	const orderId = getRootOrderId(order);

	const hasServerSavedSuborders = checkIfHasSubordersSavedOnServer(order);
	const rootSuborder = Object.keys(orderInState ?? {})?.length > 0 ? orderInState : createRootSuborder(order);

	let suborders: Suborder[];
	let subordersCount = 0;
	let hasPaintToningSuborder = false;
	let paintToningSubordersCount = 0;

	const locker: Locker = {};

	if (hasServerSavedSuborders) {
		suborders = [
			rootSuborder,
			...order.subOrders.map((suborder, idx) => {
				if (checkIfSuborderHasPaintToningType(suborder) && !hasPaintToningSuborder) {
					hasPaintToningSuborder = true;
					paintToningSubordersCount++;
				}
				// hydrate locker
				locker[idx + 1] = [...(suborder?.products ?? []), ...(suborder?.services ?? [])].map((item) => String(item.id));

				return {
					...suborder,
					services:
						suborder.services?.reduce((acc, service) => {
							// !TEMP id assignment
							const id = service.id || uuid();

							return { ...acc, [id]: { ...service, id }, isSaved: true };
						}, {}) ?? {},
					meta: {
						tabTitle: createTabTitleByType(suborder.type, idx + 1),
						isSaved: true,
					},
				};
			}),
		];
		subordersCount = suborders.length;
	} else {
		const rootSuborderCopy = { ...rootSuborder };

		if ('suborders' in rootSuborderCopy) {
			delete rootSuborderCopy.suborders;
		}

		suborders = [rootSuborderCopy];
	}

	const hasSavedServices = checkIfHasServicesSavedOnServer(order);
	const hasInMemoryAddedServices = checkIfHasInMemoryServices(stateServices);
	const hasServices = hasInMemoryAddedServices || hasSavedServices;

	const stateServicesCopy = { ...stateServices };

	let services: Service;

	if (hasServices) {
		const serializedServices = order.services?.reduce(
			(newServices, service) => ({ ...newServices, [service.id]: { ...service, isSaved: true } }),
			{} as Service,
		);

		if (hasInMemoryAddedServices) {
			const inMemoryServices = Object.entries(stateServicesCopy ?? {});

			inMemoryServices?.forEach(([serviceId, service]) => {
				if (service?.isSaved === undefined || service.isSaved === true) {
					delete stateServicesCopy[serviceId];
				} else {
					stateServicesCopy[serviceId].isSaved = false;
				}
			});
		}

		services = hasInMemoryAddedServices ? { ...stateServicesCopy, ...serializedServices } : serializedServices;
	} else {
		services = {};
	}

	const draftOrder = {
		...order,
		services,
		suborders,
	};

	return {
		draftOrder,
		orderId,
		services,
		suborders,
		subordersCount,
		hasPaintToningSuborder,
		locker,
		paintToningSubordersCount,
	};
};

function createTabTitleByType(type: string, index: number) {
	return type === 'paint_toning' ? `Заявка на тонув. ${index}` : `Заявка ${index}`;
}

export const syncRootSuborderWithRecentUpdate = ({ rootSuborder, modifiedService }: { rootSuborder: Suborder; modifiedService: IService }) => {
	const rootSuborderServices = rootSuborder.services;

	if (modifiedService?.id) {
		rootSuborderServices[modifiedService.id] = modifiedService;
	}

	rootSuborder.services = rootSuborderServices;
};
export const syncRootSuborderWithRecentDelete = ({ rootSuborder, serviceId }: { rootSuborder: Suborder; serviceId: string }) => {
	const rootSuborderServices = rootSuborder.services;
	const service = rootSuborderServices[serviceId];

	if (service) {
		delete rootSuborderServices[serviceId];
	}
};

function checkIfSuborderHasPaintToningType(suborder: Suborder) {
	return suborder?.type === 'paint_toning';
}

type AssignLockerFnParams = { locker: Locker; items: Products[] | IService[] | Services; holder: string };

export function assignLockedItems({ locker, items, holder }: AssignLockerFnParams) {
	if (Array.isArray(items)) {
		locker[holder] = items.map((item) => String(item.id));
	} else {
		locker[holder] = Object.values(items ?? {}).map((item) => item.id);
	}
}

type UnassignLockerFnParams =
	| { locker: Locker; holder: string; cascade: true }
	| { locker: Locker; holder: string; cascade?: false; items: Product[] | IService[] };

export function unassignLockedItems({ locker, holder, cascade, ...restParams }: UnassignLockerFnParams) {
	if (cascade && locker[holder]) {
		delete locker[holder];
	} else {
		const vault = locker[holder];

		if (!vault) return;

		const { items } = restParams as { items: Product[] | IService[] };

		const ids = new Set(items.map((item) => String(item.id)));
		locker[holder] = vault.filter((id) => !ids.has(id));
	}
}
export const groupServicesByType = (services: Services | IService[]): { regularServices: IService[]; paintToningServices: IService[] } => {
	const serviceList = Array.isArray(services) ? services : toArray(services);

	return serviceList.reduce(
		(acc, service) => {
			const key = service?.serviceType === 'regular' ? 'regularServices' : 'paintToningServices';
			return { ...acc, [key]: [...acc[key], service] };
		},
		{ regularServices: [], paintToningServices: [] },
	);
};

export function groupProductsByType(products: Products[]): { paintToning: Products[]; regular: Products[] } {
	return products.reduce(
		(acc, product) => {
			const key = product.title.toLowerCase().includes('фарба') ? 'paintToning' : 'regular';
			return { ...acc, [key]: [...acc[key], product] };
		},
		{ paintToning: [], regular: [] },
	);
}

export function areValidPaintToningProducts(products: Products[]) {
	const groups = groupProductsByType(products);

	if (groups.regular.length === 0) {
		return {
			success: true,
		};
	} else {
		return {
			success: false,
			failed: groups.regular,
		};
	}
}
export function areValidRegularProducts(products: Products[]) {
	const groups = groupProductsByType(products);

	if (groups.paintToning.length === 0) {
		return {
			success: true,
		};
	} else {
		return {
			success: false,
			failed: groups.paintToning,
		};
	}
}

const selectValidSuborder = (suborder: Suborder) => {
	return (
		(Array.isArray(suborder.products) && suborder.products.length > 0) ||
		(isObject<Services>(suborder.services) && toArray(suborder.services).length > 0)
	);
};

export const prepareSubordersSaveDTO = (suborders: Suborder[], etalonOrder: ISingleOrder) => {
	const [...subordersWithoutRoot] = suborders;

	const { id, typePrice } = etalonOrder;

	const serializedSuborders = subordersWithoutRoot.filter(selectValidSuborder).map((suborder) => {
		const { products, services } = suborder;

		const transformedServices = Object.keys(services).map((key) => {
			const service = services[key];
			// @ts-ignore
			if (!service.workType) {
				return {
					serviceId: service.id,
					amount: service.quantity,
					price: service.price,
				};
			} else {
				return {
					// @ts-ignore
					serviceId: service.workType.id,
					amount: service.quantity,
					price: service.price,
				};
			}
		});

		const transformNewProduct = products.map((item) => ({
			productId: item.id,
			amount: item.amount,
			price: item.prices.reduce((acc, curr) => (curr.typePrice.id === typePrice.id ? curr.price : acc), 0),
		}));

		return {
			note: '',
			services: transformedServices,
			parentId: id,
			clientId: etalonOrder.client.id,
			contractId: etalonOrder.contract.id,
			organizationId: etalonOrder.organization.id,
			typePriceId: etalonOrder.typePrice.id,
			responsibleId: etalonOrder.responsible['1c_uuid'],
			products: transformNewProduct,
			stockId: etalonOrder.stock.id,
			tempId: suborder?.tempId,
			title: suborder?.meta?.tabTitle,
			id: suborder.id,
			type: suborder.type,
		};
	});

	// const { products, services } = rootOrder;
	// const dataToBeSent = { products, subOrders: serializedSuborders, services: toArray(services) };

	// const dto = { ...etalonOrder, ...dataToBeSent };

	return serializedSuborders;
};

export const assignReservationStatus = <TData>(obj: TData) => {
	const assigned = { ...obj, status: ORDER_RESERVATION_STATUS };

	return assigned;
};

export const unassignReservationStatus = <TData>(obj: TData) => {
	const assigned = { ...obj, status: ORDER_NEWLY_CREATED_STATUS };

	return assigned;
};

export const isSuborderEmpty = (suborder?: Suborder) => {
	if (!suborder) return true;

	const isEmpty = !suborder.products?.length && !toArray(suborder.services).length;

	return isEmpty;
};
